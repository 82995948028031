import { PiEnvelopeThin } from "react-icons/pi";
import { LuPhone } from "react-icons/lu";

import FooterColumn from "./FooterColumn";
import ColumnItem from "./ColumnItem";

const Footer = () => {
  return (
    <div className="h-full flex sm:flex-col sm:gap-5 justify-between py-10 px-16 sm:px-5 bg-primary text-white">
      <FooterColumn useLogo>
        <div className="flex flex-col gap-1">
          <ColumnItem
            icon={<PiEnvelopeThin className="text-[18px]" />}
            text="Softschoole@gmail.com"
          />
          <ColumnItem
            icon={<LuPhone className="text-[18px]" />}
            text="08135696959,08084639681"
          />
        </div>
      </FooterColumn>
      <FooterColumn title="Company">
        <ColumnItem text="About Us" link={"#"} />
        <ColumnItem text="Contact Us" link={"#"} />
      </FooterColumn>
      <FooterColumn title="Support">
        <ColumnItem text="Help & Support" link={"#"} />
        <ColumnItem text="Privacy Policy" link={"#"} />
        <ColumnItem text="FAQs" link={"#"} />
      </FooterColumn>
      <FooterColumn title="Quick Links" />
    </div>
  );
};

export default Footer;
