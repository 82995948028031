import api from "../apiSlice";
import axios from "axios";
import { tags } from "../../Utils/constants";

const profile = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query({
      query: () => ({
        url: `/profile/me`,
      }),
      providesTags: [tags.MY_PROFILE_CONTROL_TAG],
    }),

    getSchoolProfile: builder.query({
      query: () => ({
        url: `/profile/school`,
      }),
      providesTags: [tags.SCHOOL_PROFILE_CONTROL_TAG],
    }),

    getSchoolInfo: builder.query({
      query: (shortName) => ({
        url: `/profile/school-info?shortName=${shortName}`,
      }),
    }),

    editMyProfile: builder.mutation({
      query: (data) => ({
        url: "/profile/edit-me",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [tags.MY_PROFILE_CONTROL_TAG],
    }),

    editSchoolProfile: builder.mutation({
      query: (data) => ({
        url: "/profile/edit-school",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [tags.SCHOOL_PROFILE_CONTROL_TAG],
    }),
  }),
});

export const uploadFile = async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/file/upload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    },
  );
  return response;
};

export const {
  useGetMyProfileQuery,
  useGetSchoolProfileQuery,
  useGetSchoolInfoQuery,
  useEditMyProfileMutation,
  useEditSchoolProfileMutation,
} = profile;
