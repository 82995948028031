import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { genderList } from "Utils/constants";
import { useGetApplicationsQuery } from "Network/applications";
import { useCreateApplicantsMutation } from "Network/applicants";
import useUploadFileHooks from "../../../../hooks/File/useUploadFileHooks";

export const applicantValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required"),
  dob: Yup.date().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string(),
  classId: Yup.string().required("Class ID is required"),
  applicationId: Yup.string().required("Application ID is required"),
  medicalInfo: Yup.string(),
  photo: Yup.string(),
  supportingDocuments: Yup.array(),
  parentOrGuardianInfo: Yup.object().shape({
    fullName: Yup.string().required("Full name of parent/guardian is required"),
    relationShip: Yup.string().required("Relationship is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  }),
});

const useCreateApplicants = () => {
  const [createApplicants, applicantsState] = useCreateApplicantsMutation();

  const [selectedGender, setSelectedGender] = useState(genderList[0]);

  const [selectedApplication, setSelectedApplication] = useState();

  const [selectedClass, setSelectedClass] = useState();

  const applications = useGetApplicationsQuery({
    page: undefined,
    published: true,
  });

  const { resetFile } = useUploadFileHooks();

  const singleApplication = useGetApplicationsQuery(
    { id: selectedApplication?.value },
    {
      skip: !selectedApplication,
      selectFromResult: ({ data }) => ({
        application: data?.data?.find(
          (application) => application._id === selectedApplication?.value,
        ),
      }),
    },
  );

  useEffect(() => {
    setSelectedClass();
  }, [singleApplication?.application]);

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: selectedGender?.value,
    address: "",
    classId: "",
    applicationId: "",
    medicalInfo: "",
    photo: undefined,
    supportingDocuments: [],
    parentOrGuardianInfo: {
      fullName: "",
      relationShip: "",
      phone: "",
      email: "",
    },
  };

  const getCategoryId = useCallback(() => {
    return (
      singleApplication?.application?.classes?.find(
        (cl) => cl?._id === selectedClass?.value,
      )?.category?._id || undefined
    );
  }, [selectedClass?.value]);

  const handleSubmit = (values, actions) => {
    const data = {
      ...values,
      sectionId: singleApplication?.application?.section?._id,
      categoryId: getCategoryId(),
      address: values?.address || undefined,
      middleName: values.middleName || undefined,
      medicalInfo: values.medicalInfo || undefined,
    };

    createApplicants({ data })
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toast.success("Application created");
        setSelectedClass();
        setSelectedGender(genderList[0]);
        setSelectedApplication();
        resetFile();
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return {
    selectedGender,
    setSelectedGender,
    selectedApplication,
    setSelectedApplication,
    selectedClass,
    setSelectedClass,
    applications,
    singleApplication,
    initialValues,
    validationSchema: applicantValidationSchema,
    handleSubmit,
    applicantsState,
  };
};

export default useCreateApplicants;
