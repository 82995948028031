import api from "../apiSlice";
import { tags } from "../../Utils/constants";
import { getSchoolId } from "../utils";

const section = api.injectEndpoints({
  endpoints: (builder) => ({
    getSections: builder.query({
      query: () => ({
        url: `/sections/schools/${getSchoolId()}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: tags.SCHOOL_SECTIONS, id })),
              { type: tags.SCHOOL_SECTIONS, id: "LIST" },
            ]
          : [{ type: tags.SCHOOL_SECTIONS, id: "LIST" }],
    }),

    getSection: builder.query({
      query: (id) => ({
        url: `/sections/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: tags.SCHOOL_SECTIONS, id }],
    }),

    getSectionClasses: builder.query({
      query: (id) => ({
        url: `/sections/${id}/classes`,
      }),
      providesTags: (result, error, id) => [{ type: tags.SCHOOL_SECTIONS, id }],
    }),

    createSection: builder.mutation({
      query: (data) => ({
        url: "/sections",
        method: "POST",
        body: {
          ...data,
          schoolId: getSchoolId(),
        },
      }),
      invalidatesTags: [{ type: tags.SCHOOL_SECTIONS, id: "LIST" }],
    }),

    editSection: builder.mutation({
      query: ({ data, id }) => ({
        url: `/sections/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_SECTIONS, id: arg._id },
      ],
    }),

    deleteSection: builder.mutation({
      query: ({ id }) => ({
        url: `/sections/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.SCHOOL_SECTIONS, id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetSectionsQuery,
  useGetSectionClassesQuery,
  useCreateSectionMutation,
  useEditSectionMutation,
  useDeleteSectionMutation,
  useGetSectionQuery,
} = section;
