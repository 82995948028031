export const parentData = {
  imagePath: "/images/parent.png",
  title: "Parent",
  variant: "left",
  features: [
    {
      title: "School Search & Application",
      text: "Find and apply to schools Online with personalized recommendations",
    },
    {
      title: "Fee Payment",
      text: "Pay your Childs School fees and securely track payment History",
    },
    {
      title: "Progress Monitoring",
      text: "Track your Child Academic performance and Attendance ",
    },
    {
      title: "Direct Communication",
      text: "Stay Connected with teachers and receive important updates",
    },
  ],
};

export const schoolData = {
  imagePath: "/images/school.png",
  title: "School",
  variant: "right",
  features: [
    {
      title: "Admission Management",
      text: "Streamline the admission process from application to enrollment while managing applicant information and communicating with prospective students.",
    },
    {
      title: "Student & Staff Management",
      text: "Efficiently manage Student records and staff details",
    },
    {
      title: "Progress Monitoring",
      text: "Generate Financial reports and monitor fee collection ",
    },
    {
      title: "Communication Tool",
      text: "Centralize Communication between Staff, Parent and Student",
    },
  ],
};

export const studentData = {
  imagePath: "/images/student.png",
  title: "Student",
  variant: "left",
  features: [
    {
      title: "Learning Resources",
      text: "Access a personalized dashboard to view and download class materials, assignments, and notes for all academic content.",
    },
    {
      title: "Assignment Submission",
      text: "Submit homework and projects online, and receive grades and feedback directly from teachers.",
    },
    {
      title: "Progress Tracking",
      text: "Monitor Academic performance with real time, access grades and attendance records",
    },
  ],
};

export const testimonials = [
  {
    title: "Parent",
    image: "",
    text: "“Soft has made finding the right school for my child so much easier, the personalize recommendation were spot on and i love how i can track my child’s progress and pay fees all in one place”",
    variant: "primary",
  },

  {
    title: "School Admin",
    image: "",
    text: "“Managing our school has never been this efficient, sofschool has streamlined our administrative tasks so easy, allowing us to fucus more on improving the quality of education ”",
    variant: "secondary",
  },
  {
    title: "Teacher",
    image: "",
    text: "“Softschool has completely transformed how i manage my classes, the ability to communicate with parent directly and access student records in real-time  ”",
    variant: "primary",
  },
];

export const faqs = [
  {
    title: "How does Softschool simplify School Management? ",
    text: "It makes the difficult things as easy.",
  },
  {
    title: "Can I import existing data into Softschool?",
    text: "Not yet, but Softschool is working towards that.",
  },
  {
    title: "Is there any Customer support Available ?",
    text: "Yes can send an email to Softschoole@gmail.com or call 08135696959,08084639681.",
  },
];
