/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useGetBankQuery } from "Network/Configurations/bank";

const useGetBank = (setForm) => {
  const bankQuery = useGetBankQuery();

  useEffect(() => {
    if (bankQuery.isSuccess) {
      setForm();
    }
  }, [bankQuery.isFetching]);

  return { bankQuery };
};

export default useGetBank;
