import ii from "../../../../Assets/child.png";

const Hero = () => {
  const heroBg = "/images/website-hero-1-bg.png";
  const mobileHeroBg = "/images/website-hero-mobile.png";
  const people = "/images/people.png";
  const mobilePeople = "/images/people-mobile.png";
  const bottomHeroBg = "/images/hero-bottom-bg.png";

  return (
    <div className="w-full h-full">
      <div
        style={{
          "--image-url": `url(${heroBg})`,
          "--mobile-image-url": `url(${mobileHeroBg})`,
        }}
        className="h-[900px] sm:px-5 sm:h-auto bg-[image:var(--image-url)] sm:bg-[image:var(--mobile-image-url)] bg-cover bg-no-repeat flex flex-col gap-24 sm:gap-5 items-center"
      >
        <p className="font-bold text-[40px] sm:text-[18px] pt-[200px] sm:pt-12 sm:w-full w-[70%] mx-auto text-white text-center">
          Softschool the Ultimate Solution for effortless Educational Management
        </p>

        <img src={people} alt="" className="sm:hidden" />
        <img src={mobilePeople} alt="" className="hidden sm:block" />
      </div>

      <div
        style={{
          "--image-url": `url(${bottomHeroBg})`,
        }}
        className="h-auto pt-[350px] sm:pt-8 pb-24 sm:pb-0 bg-[image:var(--image-url)] sm:bg-none bg-contain bg-no-repeat flex flex-col items-center gap-24 sm:gap-10 sm:px-5"
      >
        <p className="font-bold text-[40px] sm:text-[18px]  text-[#00315F] text-center sm:w-full w-[70%] mx-auto sm:mx-3">
          Transform your School Operation With a Powerful easy to use Platform
        </p>

        <video
          controls
          className="self-center h-[400px] sm:h-[201px] w-[600px] sm:w-[291px]"
        >
          <source src="path/to/your/video.mp4" type="video/mp4" />
          <track
            src="path/to/your/captions.vtt"
            kind="captions"
            srcLang="en"
            label="English"
            default
          />
        </video>

        <p className="font-bold text-[40px] sm:text-[18px] text-[#00315F] text-center sm:w-full w-[70%] mx-auto sm:mx-3">
          Explore how Softschool will simplify your Educational Management
        </p>
      </div>
    </div>
  );
};

export default Hero;
