import { useState } from "react";
import {
  useGetDocumentsQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
} from "../../../../../Network/Configurations/supportingDocument";

import { toast } from "react-toastify";

const useGetAttachmentQuery = () => {
  const documents = useGetDocumentsQuery();
  const [creatDocument, createDocumentState] = useCreateDocumentMutation();
  const [deleteDocument, deleteDocumentState] = useDeleteDocumentMutation();
  const [editDocument, editDocumentState] = useEditDocumentMutation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = (values, actions) => {
    creatDocument(values)
      .unwrap()
      .then(() => actions.resetForm())
      .catch(() => toast.error("Something went wrong"));
  };

  const handleDelete = () => {
    deleteDocument({ id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Document deleted successfully");
        setConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  const handleUpdate = (value) => {
    editDocument({ data: { name: value }, id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Document Edited successfully");
        setOpenUpdateModal(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  return {
    documents,
    createDocumentState,
    deleteDocumentState,
    editDocumentState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
  };
};

export default useGetAttachmentQuery;
