import Select from "Component/Forms/Select";
import CustomModal from "Component/Forms/CustomModal";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useEditApplication from "../hooks/useEditApplication";

const ViewApplication = ({ open, setOpen, data }) => {
  const {
    selectedClasses,
    selectedDocument,
    selectedPaymentMode,
    selectedSection,
    selectedRequirement,
    selectedAttachment,
    formik,
  } = useEditApplication(data, setOpen);

  return (
    <CustomModal
      open={open}
      handleClose={() => setOpen(false)}
      title="View Application"
      className="w-[35%] sm:w-[95%] max-h-[90%] sm:max-h-[95%] overflow-y-auto"
    >
      <div className="flex flex-col h-full mt-5 gap-4 w-full ">
        <InputNoFormik
          withTitle
          title="Admission Title"
          value={formik.values.description}
          name="description"
        />
        <Select
          label="Section"
          mainContainerStyle="sm:w-[100%]  text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          data={[]}
          value={selectedSection}
        />
        <Select
          label="Classes"
          mainContainerStyle="sm:w-[100%] w-[100%] text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          selectMultiple
          data={[]}
          value={selectedClasses}
        />
        <InputNoFormik
          withTitle
          type="number"
          title="Fees"
          value={formik.values.fee}
          name="fee"
        />
        <Select
          label="Supporting document"
          mainContainerStyle="sm:w-[100%] w-[100%] text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          selectMultiple
          data={[]}
          value={selectedDocument}
        />

        <Select
          label="Entry Requirement"
          mainContainerStyle="sm:w-[100%] w-[100%] text-[14px]"
          selectMultiple
          data={[]}
          value={selectedRequirement}
        />

        <Select
          label="Attach Document"
          mainContainerStyle="sm:w-[100%] w-[100%] text-[14px]"
          selectMultiple
          data={[]}
          value={selectedAttachment}
        />
        <Select
          label="Payment type"
          mainContainerStyle="w-[100%] text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          data={[]}
          value={selectedPaymentMode}
        />
        <div className="flex justify-end">
          <Button
            handleSubmit={() => setOpen(false)}
            title="Cancel"
            className="w-fit text-[12px] py-2 px-4 font-medium"
            variant="outline"
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default ViewApplication;
