import CustomModal from "Component/Forms/CustomModal";
import useAuthStore, {
  AuthModalPages,
} from "Screens/SchoolWebpages/hooks/useAuthStore";
import Signup from "./Signup";
import Login from "./Login";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";

const Auth = () => {
  const { open, setOpen, page, loading } = useAuthStore();

  return (
    <CustomModal
      open={open}
      handleClose={() => !loading && setOpen(false)}
      title={page.title}
      className="overflow-y-auto max-h-[100vh]"
    >
      {AuthModalPages.LOGIN.id === page.id && <Login />}
      {AuthModalPages.SIGNUP.id === page.id && <Signup />}
      {AuthModalPages.FORGET_PASSWORD.id === page.id && <ForgetPassword />}
      {AuthModalPages.RESET_PASSWORD.id === page.id && <ResetPassword />}
    </CustomModal>
  );
};

export default Auth;
