import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

const CardLayout = ({ title, children, isLoading }) => {
  return (
    <div className="flex flex-col gap-4 ">
      <h2 className=" font-medium">{title}</h2>

      {!isLoading ? (
        children
      ) : (
        <div className="w-full flex justify-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default CardLayout;

CardLayout.prototype = {
  title: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

CardLayout.defaultProps = {
  title: "",
  isLoading: false,
};
