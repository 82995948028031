import { Formik } from "formik";

import Select from "Component/Forms/Select";
import CustomModal from "Component/Forms/CustomModal";
import Input, { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import UploadImage from "Screens/AdmissionScreen/Applicants/Manage/CreateApplicant/UploadImage";
import { genderList } from "Utils/constants";

import useApplicationStore from "../hooks/useApplicationStore";
import useCreateApplicants from "../hooks/useCreateApplicants";
import useUploadDocuments from "hooks/File/useUploadDocuments";
import { isParentLoggedIn } from "Screens/SchoolWebpages/component/utils";

const ApplicationModal = () => {
  const isLoggedIn = isParentLoggedIn();
  const {
    open,
    setOpen,
    selectApplication: application,
    selectedClass,
    clearStore,
  } = useApplicationStore();

  const {
    selectedGender,
    setSelectedGender,
    selectedApplication,
    transformedSelectedClass,
    initialValues,
    validationSchema,
    handleSubmit,
    applicantsState,
  } = useCreateApplicants({ application, selectedClass });

  const {
    selectedFiles,
    handleFileChange,
    handleFileUpload,
    handleClear,
    uploadingDocument,
  } = useUploadDocuments();

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        setOpen(false);
        clearStore();
      }}
      title={`Application into ${selectedClass?.name}`}
      message="You will have to create an account or login to be able to apply"
      showMessage={!isLoggedIn}
      className="w-[70%] max-w-[70%] sm:w-[95%] sm:max-w-[95%] max-h-[90%] sm:max-h-[95%] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="bg-white">
            <UploadImage
              onFileChanged={(e) => {
                props.setFieldValue("photo", e);
              }}
            />

            <div className="flex flex-col gap-6 px-6 sm:px-0 py-6 pt-0 ">
              <div className="grid grid-cols-3 sm:flex sm:flex-col sm:gap-3 gap-4">
                <Input
                  withTitle
                  title="First Name"
                  placeholder="Enter first name"
                  name="firstName"
                />
                <Input
                  withTitle
                  title="Middle Name"
                  placeholder="Enter first name"
                  name="middleName"
                />
                <Input
                  withTitle
                  title="Last Name"
                  placeholder="Enter first name"
                  name="lastName"
                />
                <Input
                  withTitle
                  title="Date of Birth"
                  name="dob"
                  type="date"
                  inputStyle="py-2"
                />

                <Select
                  withLabel
                  label="Gender"
                  data={genderList}
                  value={selectedGender}
                  className={`${props.errors?.gender && "border-[red] "}`}
                  onChangeOption={(x) => {
                    props.setFieldValue("gender", x?.value);
                    setSelectedGender(x);
                  }}
                />

                <Input
                  withTitle
                  title="Address"
                  placeholder="Enter Address"
                  name="address"
                />

                <Select
                  mainContainerStyle="col-span-3"
                  withLabel
                  label="Application"
                  disabled
                  value={selectedApplication}
                />

                <div className="grid grid-cols-2 sm:flex sm:flex-col sm:gap-3 gap-4 col-span-3 ">
                  <InputNoFormik
                    withTitle
                    title="Section"
                    value={application?.section?.name}
                    placeholder="Appliaction section will show here..."
                    disabled
                  />

                  <Select
                    withLabel
                    label="Class"
                    value={transformedSelectedClass}
                    disabled
                  />
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <h3 className="font-bold sm:text-[14px] sm:font-semibold">
                  Medical Information
                </h3>
                <Input
                  withTitle
                  title="Does the child have any medical challenges (state if any)"
                  placeholder="Enter medical challenges"
                  name="medicalInfo"
                />
              </div>

              <div className="flex flex-col gap-1">
                <h3 className="font-bold sm:text-[14px] sm:font-semibold">
                  Guardian/Parent Information
                </h3>
                <div className="grid grid-cols-2 sm:flex sm:flex-col sm:gap-3 gap-4">
                  <Input
                    withTitle
                    title="Full Name"
                    placeholder="Enter Full Name"
                    name="parentOrGuardianInfo.fullName"
                  />
                  <Input
                    withTitle
                    title="Phone Number"
                    placeholder="000-0000-0000"
                    name="parentOrGuardianInfo.phone"
                  />
                  <Input
                    withTitle
                    title="Relationship"
                    placeholder="Example: Father, Mother, Sibling etc"
                    name="parentOrGuardianInfo.relationShip"
                  />
                  <Input
                    withTitle
                    title="Email"
                    placeholder="Enter Email"
                    name="parentOrGuardianInfo.email"
                  />
                </div>
              </div>

              {application?.supportingDocuments.length > 0 && (
                <div className="flex flex-col gap-1">
                  <h3 className="font-bold sm:text-[14px] sm:font-semibold">
                    Documents
                  </h3>

                  <div className="flex flex-col gap-3">
                    {application?.supportingDocuments?.map((document) => (
                      <div className="flex sm:flex-col sm:gap-4 gap-2 items-end">
                        <InputNoFormik
                          withTitle
                          disabled={props.values.supportingDocuments.find(
                            (doc) => doc?.name === document?.name,
                          )}
                          title={document?.name}
                          name={document?.name}
                          type="file"
                          handleChange={(e) =>
                            handleFileChange(e, document?.name)
                          }
                        />

                        <div className="flex gap-2">
                          {props.values.supportingDocuments.find(
                            (doc) => doc?.name === document?.name,
                          ) ? (
                            <Button
                              title="Clear"
                              variant="outline"
                              handleSubmit={() =>
                                handleClear(
                                  document.name,
                                  props.setFieldValue,
                                  props.values,
                                )
                              }
                              className="h-[47px] w-[100px] sm:text-[11px] sm:h-[30px] sm:w-[60px]"
                            />
                          ) : (
                            <Button
                              title="Upload"
                              isLoading={uploadingDocument === document?.name}
                              className="h-[47px] sm:h-[30px] w-[100px] sm:w-[60px] sm:text-[11px]"
                              handleSubmit={() => {
                                handleFileUpload(
                                  selectedFiles[document?.name],
                                  document.name,
                                  props.setFieldValue,
                                  props.values,
                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="flex justify-between sm:gap-10">
                <Button
                  variant="outline"
                  disabled={applicantsState.isLoading}
                  className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                  title="Close"
                  handleSubmit={() => setOpen(false)}
                />

                <Button
                  disabled={!isLoggedIn}
                  isLoading={applicantsState.isLoading}
                  className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                  title="Submit"
                  handleSubmit={props.handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default ApplicationModal;
