import { IoAddCircleOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import {
  TableWrapper,
  THead,
  TRow,
  TableLoader,
  TableNoData,
} from "Component/Table";
import {
  MobileTableWrapper,
  MobileTRow,
  MobileTableNoData,
  MobileTableLoader,
} from "Component/Table/Mobile";
import EditApplication from "./Manage/EditApplication";
import ViewApplication from "./View/ViewApplication";
import Actions from "./Manage/Actions";
import ConfirmBox from "Component/ConfirmBox";
import ConfirmDelete from "Component/ConfirmDelete";
import useApplication from "./hooks/useApplication";
import { CURRENCY_SYMBOL, statusConstant } from "Utils/constants";
import Pagination from "Component/Pagination/Pagination";
import CustomSearch from "Component/CustomSearch";
import { admissionRoute } from "Utils/navigationRoutes";
import StatusIdentifier from "Component/StatusIdentifier";

const Applications = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    handleDelete,
    deleteApplicationsState,
    handlePublish,
    publishApplicationsState,
    applicationsState,
    openEdit,
    setOpenEdit,
    openConfirmBox,
    setOpenConfirmBox,
    selectedItems,
    setSelectedItems,
    openConfirmDelete,
    setOpenConfirmDelete,
    openView,
    setOpenView,
    handleOpenView,
    filters,
  } = useApplication();

  return (
    <MainLayout>
      <div className="flex flex-col gap-3 mt-[10px]">
        <BodyHeaderLayout title={"Manage Applications"}>
          <div className="flex gap-3 sm:w-[100%]">
            <Button
              Icon={IoAddCircleOutline}
              withIcon
              className="w-fit sm:w-[50%] text-[12px] h-[41px] "
              title="Create Applications"
              handleSubmit={() =>
                navigate(
                  `/${params.shortName}/${admissionRoute.createApplications}`,
                )
              }
            />
          </div>
        </BodyHeaderLayout>

        <div className="flex justify-between sm:flex-col mb-[20px] bg-white p-3">
          <CustomSearch
            className="w-[40%] sm:w-[100%] text-[14px]  "
            placeholderText="Search by name, section, class or admission status"
            handleChange={(e) => filters.handleSearch(e.target.value)}
          />
        </div>

        <TableWrapper>
          <table className="w-full ">
            <THead>
              <td className="flex justify-center py-5 min-w-[2%] px-6">S/N</td>
              <td className="w-[18%] px-2">Name</td>
              <td className="w-[16%] px-2">Section</td>
              <td className="w-[12%] px-2">Classes</td>
              <td className="w-[16%] px-2">Fees</td>
              <td className="w-[16%] px-2">Payment type</td>

              <td className="w-[18%] px-2">Status</td>

              <td className="w-[2%] pr-6">Actions</td>
            </THead>
            {!applicationsState.isFetching ? (
              applicationsState?.data?.data?.length !== 0 ? (
                <tbody>
                  {applicationsState?.data?.data?.map((app, index) => (
                    <TRow
                      key={index}
                      className="odd:bg-[#f7f6f6] even:bg-white text-[12px] hover:bg-blue-100 "
                    >
                      <td className="px-6 py-5">{index + 1}</td>
                      <td className="px-2">{app?.description}</td>
                      <td className="px-2">{app?.section?.name}</td>
                      <td className="px-2">
                        {app?.classes[0]?.name}{" "}
                        {app?.classes?.length > 1 && "..."}
                      </td>
                      <td className="px-2">
                        {CURRENCY_SYMBOL} {Number(app?.fee).toLocaleString()}
                      </td>
                      <td className="px-2">{app?.paymentMode}</td>

                      <td className="px-2">
                        <StatusIdentifier
                          status={
                            app?.isPublished
                              ? statusConstant.published
                              : statusConstant.notPublish
                          }
                        />
                      </td>

                      <td>
                        <Actions
                          data={app}
                          setSelectedItems={setSelectedItems}
                          setOpenEdit={setOpenEdit}
                          setOpenConfirmBox={setOpenConfirmBox}
                          setOpenConfirmDelete={setOpenConfirmDelete}
                          handleOpenView={handleOpenView}
                        />
                      </td>
                    </TRow>
                  ))}
                </tbody>
              ) : (
                <TableNoData colSpan={8} />
              )
            ) : (
              <TableLoader colSpan={8} />
            )}
          </table>
        </TableWrapper>

        <MobileTableWrapper>
          {!applicationsState.isFetching ? (
            applicationsState?.data?.data?.length !== 0 ? (
              applicationsState?.data?.data?.map((app, index) => (
                <div
                  key={index}
                  className="flex gap-5 px-3 py-2 rounded bg-white  shadow-small-select  text-[14px] text-primary "
                >
                  <table className="w-full">
                    <MobileTRow
                      action
                      title={"Section"}
                      text={app?.section?.name}
                      actionTrigger={
                        <Actions
                          data={app}
                          setSelectedItems={setSelectedItems}
                          setOpenEdit={setOpenEdit}
                          setOpenConfirmBox={setOpenConfirmBox}
                          setOpenConfirmDelete={setOpenConfirmDelete}
                          handleOpenView={handleOpenView}
                        />
                      }
                    />

                    <MobileTRow
                      title={"Classes"}
                      text={`${app?.classes[0]?.name || ""} ${app?.classes?.length > 1 ? "..." : ""}`}
                    />

                    <MobileTRow
                      title={"Fees"}
                      text={`${CURRENCY_SYMBOL} ${Number(app?.fee || "").toLocaleString()}`}
                    />

                    <MobileTRow
                      title={"Payment type"}
                      text={app?.paymentMode}
                    />

                    <MobileTRow
                      title={"Documents"}
                      text={`${app?.supportingDocuments[0]?.name || ""} ${app?.supportingDocuments?.length > 1 ? "..." : ""}`}
                    />

                    <MobileTRow title={"Status"} withBorder={false}>
                      <StatusIdentifier
                        status={
                          app?.isPublished
                            ? statusConstant.published
                            : statusConstant.notPublish
                        }
                      />
                    </MobileTRow>
                  </table>
                </div>
              ))
            ) : (
              <MobileTableNoData />
            )
          ) : (
            <MobileTableLoader />
          )}
        </MobileTableWrapper>

        {!applicationsState.isFetching &&
          applicationsState?.data?.data?.length !== 0 && (
            <div className="flex w-full justify-end mt-2">
              <Pagination
                onPageChange={filters.setPage}
                pageTotal={applicationsState?.data?.totalPages}
                initialPage={applicationsState?.data?.currentPage}
              />
            </div>
          )}
      </div>

      {openEdit && (
        <EditApplication
          open={openEdit}
          setOpen={setOpenEdit}
          data={selectedItems}
        />
      )}

      {openView && (
        <ViewApplication
          open={openView}
          setOpen={setOpenView}
          data={selectedItems}
        />
      )}

      {openConfirmBox && (
        <ConfirmBox
          openConfirmBox={openConfirmBox}
          setOpenConfirmBox={setOpenConfirmBox}
          title={
            selectedItems?.isPublished
              ? "Un Publish Application"
              : "Publish Application"
          }
          isLoading={publishApplicationsState.isLoading}
          handleConfirm={() =>
            handlePublish({
              values: { isPublished: !selectedItems?.isPublished },
              data: selectedItems,
              setOpen: setOpenConfirmBox,
            })
          }
        />
      )}

      {openConfirmDelete && (
        <ConfirmDelete
          openConfirmDelete={openConfirmDelete}
          setOpenConfirmDelete={setOpenConfirmDelete}
          title="Application"
          deleteName={selectedItems?.section?.name}
          handleDelete={() =>
            handleDelete({ data: selectedItems, setOpen: setOpenConfirmDelete })
          }
          isLoading={deleteApplicationsState.isLoading}
        />
      )}
    </MainLayout>
  );
};

export default Applications;
