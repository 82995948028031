import api from "../apiSlice";
import { tags } from "../../Utils/constants";
import { getSchoolId } from "../utils";

const bank = api.injectEndpoints({
  endpoints: (builder) => ({
    getBank: builder.query({
      query: () => ({
        url: `/bank-accounts/${getSchoolId()}`,
      }),
      transformResponse: (response) => response[0],
      providesTags: [tags.BANK_CONTROL_TAG],
    }),

    createBank: builder.mutation({
      query: (data) => ({
        url: "/bank-accounts",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [tags.BANK_CONTROL_TAG],
    }),

    editBank: builder.mutation({
      query: ({ data, id }) => ({
        url: `/bank-accounts/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [tags.BANK_CONTROL_TAG],
    }),
  }),
});

export const { useGetBankQuery, useCreateBankMutation, useEditBankMutation } =
  bank;
