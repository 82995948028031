import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useAuthStore, {
  AuthModalPages,
} from "Screens/SchoolWebpages/hooks/useAuthStore";
import { useRegisterUserMutation } from "Network/Auth/auth";
import { userRole } from "Utils/constants";

const Signup = () => {
  const { setPage, setLoading, setOpen } = useAuthStore();
  const [createUser, { isLoading }] = useRegisterUserMutation();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = (values, action) => {
    const data = {
      ...values,
      role: userRole.PARENT,
    };
    delete data.confirmPassword;
    setLoading(true);
    createUser(data)
      .unwrap()
      .then((res) => {
        action.resetForm();
        toastify.success("Account created successfully");
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err.data.message);
        setLoading(false);
      });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="flex flex-col gap-4 w-full h-auto mt-3">
          <Input
            withTitle
            title="First Name"
            placeholder="Enter First Name"
            name="firstName"
          />

          <Input
            withTitle
            title="Last Name"
            placeholder="Enter Last Name"
            name="lastName"
          />

          <Input
            withTitle
            title="Email"
            placeholder="Enter Email"
            name="email"
          />

          <Input
            withTitle
            title="Phone Number"
            placeholder="Enter Phone Number"
            name="phone"
          />

          <Input
            withTitle
            title="Password"
            placeholder="Enter Password"
            name="password"
            type="password"
          />

          <Input
            withTitle
            title="Confirm Password"
            placeholder="Enter Confirm Password"
            name="confirmPassword"
            type="password"
          />

          <div className="flex justify-between sm:gap-10">
            <Button
              disabled={isLoading}
              handleSubmit={() => setOpen(false)}
              title="Close"
              variant="outline"
              className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
            />

            <Button
              isLoading={isLoading}
              handleSubmit={props.handleSubmit}
              title="Create Account"
              className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
            />
          </div>

          <p className="text-center text-[14px] font-semibold">
            Already have an account?{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => !isLoading && setPage(AuthModalPages.LOGIN)}
            >
              Login
            </span>
          </p>
        </div>
      )}
    </Formik>
  );
};

export default Signup;
