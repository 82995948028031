import { useState, useCallback } from "react";
import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import { useFormik } from "formik";
import Select from "Component/Forms/Select";
import { InputNoFormik } from "Component/Forms/Input";
import FileUploader from "Component/Forms/FileUploader";
import { profileInitialValues, profileValidationSchema } from "./validation";
import avatar from "Assets/no-logo.png";
import useUploadFile from "hooks/File/useUploadFile";
import {
  useGetSchoolprofile,
  useEditSchoolProfile,
} from "./hooks/schoolProfile";

const SchoolProfile = () => {
  const [disabled, setDisabled] = useState(true);
  const [handleUpload, uploadState] = useUploadFile();
  const [handleEdit, editState] = useEditSchoolProfile(setDisabled);

  const formik = useFormik({
    initialValues: profileInitialValues,
    validationSchema: profileValidationSchema,
    onSubmit: (values) => {
      handleEdit({ ...values, image: uploadState?.data?.src });
    },
  });

  const {
    profileState,
    country,
    setCountry,
    state,
    setState,
    localGovernment,
    setLocalGovernment,
    resetForm,
    countryList,
    stateList,
    localGovernmentList,
  } = useGetSchoolprofile(formik);

  const handleResetForm = useCallback(() => {
    setDisabled(true);
    formik.resetForm();
    resetForm();
  }, []);

  return (
    <MainLayout isLoading={profileState.isLoading}>
      <div>
        <BodyHeaderLayout title={"School Profile Settings"} />

        <div className="flex  flex-col px-6 py-6 bg-white">
          <InputNoFormik
            className=""
            withTitle
            title="School Name"
            placeholder="Enter School Name"
            value={formik.values.schoolName}
            name="schoolName"
            handleChange={formik.handleChange}
            showError
            error={formik.errors.schoolName}
            touched={formik.touched.schoolName}
            disabled={disabled}
          />
          <div className=" grid grid-cols-2 sm:grid-cols-1 sm:gap-2 gap-3 mt-3 sm:mt-2">
            <InputNoFormik
              className="gap-0"
              withTitle
              title="Motto"
              placeholder="Enter Motto"
              value={formik.values.motto}
              name="motto"
              handleChange={formik.handleChange}
              showError
              error={formik.errors.motto}
              touched={formik.touched.motto}
              disabled={disabled}
            />
            <InputNoFormik
              className="gap-0"
              withTitle
              title="Address"
              placeholder="Address"
              value={formik.values.address}
              name="address"
              handleChange={formik.handleChange}
              showError
              error={formik.errors.address}
              touched={formik.touched.address}
              disabled={disabled}
            />
            <InputNoFormik
              className="gap-0"
              withTitle
              title="Phone Number"
              placeholder="Enter Phone Number"
              value={formik.values.contact}
              name="contact"
              handleChange={formik.handleChange}
              showError
              error={formik.errors.contact}
              touched={formik.touched.contact}
              disabled={disabled}
            />
            <Select
              label="Country"
              labelClass="text-[14px]"
              mainContainerStyle={`w-full flex flex-col gap-0`}
              className={`${formik.errors?.country && formik.touched?.country && "!border-[red] "}`}
              data={countryList}
              value={country}
              onChangeOption={(x) => {
                formik.setFieldValue("country", x?.value);
                setCountry(x);
              }}
              disabled={disabled}
            />
            <Select
              label="State"
              labelClass="text-[14px]"
              mainContainerStyle={`w-full flex flex-col gap-0`}
              className={`${formik.errors?.state && formik.touched?.state && "!border-[red] "}`}
              data={stateList}
              value={state}
              onChangeOption={(x) => {
                formik.setFieldValue("state", x?.value);
                setState(x);
              }}
              disabled={disabled}
            />

            <Select
              label="LGA"
              labelClass="text-[14px]"
              mainContainerStyle={`w-full flex flex-col gap-0`}
              className={`${formik.errors?.localGovernment && formik.touched?.localGovernment && "!border-[red] "}`}
              data={localGovernmentList}
              value={localGovernment}
              onChangeOption={(x) => {
                formik.setFieldValue("localGovernment", x?.value);
                setLocalGovernment(x);
              }}
              disabled={disabled}
            />
          </div>

          <div className="flex gap-3 sm:gap-2 sm:pt-4 sm:flex-col items-center">
            <img
              src={
                profileState?.data?.image ? profileState?.data?.image : avatar
              }
              alt=" />"
              className="h-[150px]  w-[150px] rounded-full bg-white border-1 "
            />
            <div className="mt-[20px] w-full">
              <span>School Logo</span>
              <FileUploader
                exceedSize={uploadState.exceedSize}
                disabled={disabled}
                isLoading={uploadState.isLoading}
                title=" Drag &amp; drop here or Click"
                onFileUpload={(file) => handleUpload(file[0])}
              />
            </div>
          </div>

          {disabled ? (
            <Button
              className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
              title="Edit"
              handleSubmit={() => setDisabled(false)}
            />
          ) : (
            <div className="flex gap-5">
              <Button
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Cancel"
                variant="outline"
                handleSubmit={handleResetForm}
              />
              <Button
                isLoading={editState.isLoading}
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                title="Save"
                handleSubmit={formik.handleSubmit}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default SchoolProfile;
