import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

import Nav from "../Nav/Nav";
import Header from "../Header/Header";
import Footer from "../Footer";
import useReturnSchool from "../../hooks/Auth/useReturnSchool";
import PropTypes from "prop-types";
import { useGetSessionQuery } from "../../Network/Configurations/session";
import {
  useGetSchoolProfileQuery,
  useGetMyProfileQuery,
} from "../../Network/Auth/profile";
import { LoadingPage } from "./PreLayout";

const MainLayout = ({ children, isLoading, title }) => {
  useReturnSchool();
  const session = useGetSessionQuery();
  const schoolState = useGetSchoolProfileQuery();
  const myProfileState = useGetMyProfileQuery();
  const toggleNav = useSelector((state) => state.appSlice)?.toggleNav;

  if (session.isLoading || schoolState.isLoading || myProfileState.isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="flex flex-col h-[100vh] bg-main-bg sm:bg-[#F5F5F5]">
      <Header
        schoolLogo={schoolState.data?.image}
        session={session?.data}
        userInfo={myProfileState?.data}
      />

      <div className="flex flex-grow">
        <Nav />

        <div
          className={` sm:bg-[#F5F5F5] pr-[20px] flex flex-col gap-1 ${
            toggleNav ? "pl-[265px]" : "pl-[80px]"
          }  sm:px-3 sm:py-1 pb-5 pt-[80px] sm:pt-[60px] bg-main-bg w-full`}
        >
          {!isLoading ? (
            <>
              {title.length !== 0 && (
                <div className="mt-[20px] bg-white shadow-sm font-medium px-4 py-3">
                  <h2>{title}</h2>
                </div>
              )}
              {children}
            </>
          ) : (
            <div className="flex justify-center mt-3">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

MainLayout.prototype = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
};

MainLayout.defaultProps = {
  isLoading: false,
  title: "",
};

export default MainLayout;
