import { Route } from "react-router-dom";

import { generalSettingsRoute, navigationRoutes } from "Utils/navigationRoutes";

import SchoolProfile from "Screens/AdmissionScreen/Settings/SchoolProfile";
import Profile from "Screens/Profile/Profile";
import BankControl from "Screens/AdmissionScreen/Settings/BankControl";
import SessionControl from "Screens/AdmissionScreen/Settings/SessionControl";
import Section from "Screens/AdmissionScreen/Settings/Section";
import Category from "Screens/AdmissionScreen/Settings/Category";
import AttachmentDocument from "Screens/AdmissionScreen/Settings/AttachmentDocument";

import Classes from "Screens/AdmissionScreen/Classes";
import ClassList from "Screens/AdmissionScreen/Classes/View/ClassList";

const GeneralRoutes = (
  <Route path={navigationRoutes.SHORTNAME}>
    <Route
      path={generalSettingsRoute.schoolProfile}
      element={<SchoolProfile />}
    />

    <Route path={generalSettingsRoute.profile} element={<Profile />} />

    <Route path={generalSettingsRoute.bank} element={<BankControl />} />

    <Route path={generalSettingsRoute.session} element={<SessionControl />} />
    <Route path={generalSettingsRoute.section} element={<Section />} />
    <Route path={generalSettingsRoute.category} element={<Category />} />

    <Route
      path={generalSettingsRoute.attachmentDocument}
      element={<AttachmentDocument />}
    />

    <Route path={generalSettingsRoute.schoolClass} element={<Classes />} />
    <Route path={generalSettingsRoute.classes} element={<ClassList />} />
  </Route>
);

export default GeneralRoutes;
