import CustomModal from "Component/Forms/CustomModal";
import DisplayFile from "./DispayFile";
import Button from "Component/Forms/Button";
import ConfirmBox from "Component/ConfirmBox";
import useUpdateApplicantPaymentStatus from "../hooks/useUpdateApplicantPaymentStatus";

const ViewApplicant = ({ open, applicant, handleClose = () => {} }) => {
  const {
    handleUpdateStatus,
    isLoading,
    openApprove,
    openReject,
    setOpenApprove,
    setOpenReject,
  } = useUpdateApplicantPaymentStatus({ handleCloseView: handleClose });

  const url = applicant?.proofOfPayment;
  return (
    <CustomModal
      className="max-w-[684px] h-[90vh] flex flex-col gap-3"
      open={open}
      handleClose={!isLoading && handleClose}
      title="Invoice"
    >
      <DisplayFile
        fileUrl={url}
        className="w-full h-[80%] border-1 flex justify-center overflow-y-auto"
      />

      <div>
        <p className="text-[14px]">
          Click{" "}
          <a
            className=" text-blue-500"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          to view
        </p>
      </div>

      <div className="border-t-1 w-full" />

      <div className="flex justify-between w-full">
        <Button
          className="w-[121px] sm:w-[48%] bg-[#8D0909] py-2"
          title="Reject"
          handleSubmit={() => setOpenReject(true)}
        />
        <Button
          className="w-[121px] sm:w-[48%] bg-[#248D09] py-2"
          title="Approve"
          handleSubmit={() => setOpenApprove(true)}
        />
      </div>

      {openApprove && (
        <ConfirmBox
          openConfirmBox={openApprove}
          setOpenConfirmBox={setOpenApprove}
          title="Approve Payment"
          isLoading={isLoading}
          handleConfirm={() =>
            handleUpdateStatus({
              applicantId: applicant?._id,
              status: "successful",
            })
          }
        />
      )}

      {openReject && (
        <ConfirmBox
          openConfirmBox={openReject}
          setOpenConfirmBox={setOpenReject}
          title="Reject Payment"
          isLoading={isLoading}
          handleConfirm={() =>
            handleUpdateStatus({
              applicantId: applicant?._id,
              status: "failed",
            })
          }
        />
      )}
    </CustomModal>
  );
};

export default ViewApplicant;
