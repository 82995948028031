import Layout from "../component/Layout";
import HeaderBanner from "./HeaderBanner";
import List from "./List";
import Auth from "../component/Auth";

const AdmissionPage = () => {
  return (
    <Layout>
      <HeaderBanner />
      <List />
      <Auth />
    </Layout>
  );
};

export default AdmissionPage;
