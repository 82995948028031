import { create } from "zustand";
import avatarImage from "../../Assets/avatar.png";

const useUploadFileHooks = create((set) => ({
  isLoading: false,
  data: null,
  exceedSize: false,
  fileNotAllowed: false,
  selectedImage: avatarImage,

  setIsLoading: (isLoading) => set({ isLoading }),
  setData: (data) => set({ data }),
  setExceedSize: (exceedSize) => set({ exceedSize }),
  setFileNotAllowed: (fileNotAllowed) => set({ fileNotAllowed }),

  setSelectedImage: (selectedImage) => set({ selectedImage }),

  resetFile: () =>
    set({
      data: null,
      exceedSize: false,
      isLoading: false,
      selectedImage: avatarImage,
      fileNotAllowed: false,
    }),
}));

export default useUploadFileHooks;
