import { IoIosAdd } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";

import Input from "Component/Forms/Input";
import Select from "Component/Forms/Select";
import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";

const typeList = [
  { label: "Text", value: "text" },
  { label: "Select Dropdown", value: "select" },
  { label: "Checkbox", value: "checkbox" },
];

const ApplicantFormBuilder = () => {
  const validationSchema = Yup.object().shape({
    sections: Yup.array().of(
      Yup.object().shape({
        sectionName: Yup.string().required("Section Name is required"),
        fieldType: Yup.string().required("Field Type is required"),
        title: Yup.string().when("fieldType", {
          is: (val) => Boolean(val),
          then: (s) => s.required("Title is required"),
          otherwise: (s) => s,
        }),

        options: Yup.array().when("fieldType", {
          is: (value) => value === "select" || value === "checkbox",
          then: (s) =>
            s
              .of(Yup.string().required("Option is required"))
              .min(1, "At least one option is required"),

          otherwise: (s) => s,
        }),
      }),
    ),
  });

  const initialValues = {
    sectionName: "",
    fieldType: "",
    title: "",
    options: [],
  };

  return (
    <MainLayout>
      <BodyHeaderLayout title="Applicant Form Builder" />

      <div className="bg-white p-3 rounded-md">
        <Formik
          initialValues={{
            sections: [initialValues],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log("Form Data:", values);
          }}
        >
          {(props) => (
            <Form>
              <FieldArray name="sections">
                {({ push, remove }) => (
                  <div>
                    {props.values.sections.map((section, index) => (
                      <div
                        key={index}
                        className="mb-3 flex flex-col gap-2 border-1 rounded-md p-2"
                      >
                        <Input
                          title="Section Name"
                          withTitle
                          name={`sections[${index}].sectionName`}
                        />
                        <Select
                          withLabel
                          label="Field Type"
                          data={typeList}
                          value={typeList.find(
                            (option) => option.value === section.fieldType,
                          )}
                          className={`${
                            props.errors.sections?.[index]?.fieldType &&
                            props.touched.sections?.[index]?.fieldType &&
                            "border-[red] "
                          }`}
                          onChangeOption={(selectedOption) => {
                            props.setFieldValue(
                              `sections[${index}].fieldType`,
                              selectedOption?.value,
                            );

                            // Reset options if "text" is selected
                            if (selectedOption?.value === "text") {
                              props.setFieldValue(
                                `sections[${index}].options`,
                                [],
                              );
                            }

                            // Add an empty option if "select" or "checkbox" is selected and no options exist
                            if (
                              (selectedOption?.value === "select" ||
                                selectedOption?.value === "checkbox") &&
                              section.options.length === 0
                            ) {
                              props.setFieldValue(
                                `sections[${index}].options`,
                                [""],
                              );
                            }
                          }}
                        />

                        {section.fieldType && (
                          <Input
                            title="Title"
                            withTitle
                            name={`sections[${index}].title`}
                          />
                        )}
                        {(section.fieldType === "select" ||
                          section.fieldType === "checkbox") && (
                          <FieldArray name={`sections[${index}].options`}>
                            {({ push: pushOption, remove: removeOption }) => (
                              <div className="ml-3 sm:mx-auto flex flex-col gap-2 mt-2 border-1 rounded-md p-3 w-1/2 sm:w-[95%]">
                                {section.options.map((option, optIndex) => (
                                  <div
                                    className="flex gap-2 relative"
                                    key={optIndex}
                                  >
                                    <Input
                                      className="w-[90%] sm:w-[80%]"
                                      showError={false}
                                      title={`Option ${optIndex + 1}`}
                                      withTitle
                                      name={`sections[${index}].options[${optIndex}]`}
                                    />
                                    <Button
                                      variant="icon"
                                      className="absolute right-0 top-2/3 -translate-y-1/2"
                                      handleSubmit={() =>
                                        removeOption(optIndex)
                                      }
                                      Icon={
                                        <MdDeleteForever
                                          size={20}
                                          className={`${
                                            optIndex === 0
                                              ? "text-gray-400"
                                              : "text-red-500"
                                          }`}
                                        />
                                      }
                                      disabled={optIndex === 0} // Disable delete for the first option
                                    />
                                  </div>
                                ))}

                                <Button
                                  className="mt-4 self-end"
                                  variant="icon"
                                  handleSubmit={() => pushOption("")}
                                  Icon={
                                    <IoIosAdd
                                      size={25}
                                      className="text-[#057877]"
                                    />
                                  }
                                />
                              </div>
                            )}
                          </FieldArray>
                        )}

                        <Button
                          variant="icon"
                          disabled={props.values.sections.length === 1}
                          className="self-end"
                          handleSubmit={() => remove(index)}
                          Icon={
                            <MdDeleteForever
                              size={20}
                              className="text-red-500"
                            />
                          }
                        />
                      </div>
                    ))}

                    <Button
                      variant="icon"
                      handleSubmit={() => push(initialValues)}
                      Icon={<IoIosAdd size={25} className="text-[#057877]" />}
                    />
                  </div>
                )}
              </FieldArray>

              <div className="w-full flex justify-end mt-3">
                <Button title="Submit" handleSubmit={props.handleSubmit} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MainLayout>
  );
};

export default ApplicantFormBuilder;
