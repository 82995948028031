import { useState } from "react";

const usePaginationAndSearch = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const handleSearch = (text) => {
    if (text.length >= 3 || text === "") {
      setSearch(text);
    }
  };
  return {
    page,
    setPage,
    search,
    setSearch,
    handleSearch,
  };
};

export default usePaginationAndSearch;
