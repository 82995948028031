import {
  MobileTableWrapper,
  MobileTRow,
  MobileTableLoader,
} from "Component/Table/Mobile";
import Actions from "../Manage/Actions";
import Avatar from "Component/Avatar";
import StatusIdentifier from "Component/StatusIdentifier";

const ApplicantMobileView = ({ handleView = () => {}, applicantsState }) => {
  const { data: applicants, isFetching } = applicantsState;
  return (
    <div>
      {!isFetching ? (
        <MobileTableWrapper>
          {applicants?.data?.map((applicant, index) => (
            <div
              key={index}
              className="flex gap-5 px-3 py-2 rounded bg-white  shadow-small-select  text-[14px]  "
            >
              <table className="w-full">
                <MobileTRow
                  title={"Applicant ID"}
                  actionTrigger={
                    <Actions handleView={handleView} data={applicant} />
                  }
                >
                  <div className="flex gap-2 items-center py-[6px] w-full h-full">
                    <Avatar image={applicant?.photo} />
                    <span className="">
                      {applicant?.applicantNumber || "Applicant ID"}
                    </span>
                  </div>
                </MobileTRow>

                <MobileTRow
                  action
                  title={"Name"}
                  text={`${applicant?.firstName} ${applicant?.middleName || ""} ${applicant?.lastName}`}
                />

                <MobileTRow title={"Section"} text={applicant?.section?.name} />

                <MobileTRow title={"Class"} text={applicant?.class?.name} />

                <MobileTRow title={"Admission Status"}>
                  <StatusIdentifier status={applicant?.admissionStatus} />
                </MobileTRow>
              </table>
            </div>
          ))}
        </MobileTableWrapper>
      ) : (
        <MobileTableLoader />
      )}
    </div>
  );
};

export default ApplicantMobileView;
