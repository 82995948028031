import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";

const SecondScreen = ({ back, formikProps, loading }) => {
  return (
    <div className="flex flex-col gap-5">
      <div className="text-[14px]  text-primary flex flex-col justify-between">
        <h2 className="text-[16px] font-bold">Basic Info</h2>
        <p className="!text-slate-500 !text-[12px]">
          Personal Information about yourself
        </p>
      </div>

      <div className="flex flex-col gap-4 w-[100%]">
        <div className="flex gap-5 w-full sm:flex-col">
          <Input
            withAsterik
            withTitle
            title="First Name"
            labelStyle="font-semibold"
            placeholder="First Name"
            name="firstName"
          />
          <Input
            withAsterik
            withTitle
            title="Last Name"
            labelStyle="font-semibold"
            placeholder="Last Name"
            name="lastName"
          />
        </div>
        <div className="flex gap-5 w-full sm:flex-col ">
          <Input
            withAsterik
            withTitle
            title="Email Address"
            labelStyle="font-semibold"
            placeholder="Enter email address"
            name="email"
          />
          <Input
            withAsterik
            withTitle
            title="Contact Number"
            labelStyle="font-semibold"
            placeholder="Enter phone number"
            name="phone"
          />
        </div>
        <div className="flex gap-5 sm:flex-col">
          <Input
            withAsterik
            withTitle
            title="Password"
            labelStyle="font-semibold"
            placeholder="Enter password"
            type="password"
            name="password"
          />

          <Input
            withAsterik
            withTitle
            title="Confirm Password"
            labelStyle="font-semibold"
            placeholder="Enter confrim password"
            type="password"
            name="confirmPassword"
          />
        </div>
      </div>

      <div className="flex justify-between">
        <Button
          className="w-20 h-9 !bg-white !text-[black] border-1 border-primary"
          handleSubmit={back}
          title="Previous"
        />

        <Button
          isLoading={loading}
          handleSubmit={formikProps.handleSubmit}
          title="Submit"
          className="w-20 h-9"
        />
      </div>
    </div>
  );
};

export default SecondScreen;
