import { useState, useCallback } from "react";
import { CiWarning } from "react-icons/ci";
import Skeleton from "react-loading-skeleton";

import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import {
  useSetSessionMutation,
  useGetSessionQuery,
} from "Network/Configurations/session";
import Select from "Component/Forms/Select";
import { sessionList } from "Utils/constants";
import { InputNoFormik } from "Component/Forms/Input";

const SessionControl = () => {
  const [session, setSession] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [customSession, setCustomSession] = useState(false);

  const [handleSession, state] = useSetSessionMutation();

  const { data, isFetching } = useGetSessionQuery();

  const handleSubmit = useCallback(() => {
    handleSession({ session: session?.value }).then(() => {
      setSession({});
      setDisableButton(true);
    });
  }, [session?.value]);

  return (
    <MainLayout>
      <div>
        <div className="flex bg-[#b92a2a] items-center p-3 w-[100%] sm:gap-3 text-white font-bold">
          <CiWarning className="text-[33px] w-[5%] sm:w-[20%] " />
          <p className="text-[12px] ">
            This page contains a vital information, and cannot be unchanged once
            performed, if you are not the admin kindly ignore or contact admin
            for help{" "}
          </p>
        </div>

        <div className="mt-[20px] flex sm:flex-col justify-between items-center sm:items-start sm:gap-2">
          <h2>Admission Session Control</h2>

          <CurrentSession session={data?.session} isLoading={isFetching} />
        </div>
        <div>
          <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
            <div className="flex flex-col gap-4 w-full">
              {!customSession ? (
                <Select
                  withLabel
                  label="Select Session"
                  className="h-[41px]"
                  data={sessionList}
                  value={session}
                  onChangeOption={(x) => {
                    setSession(x);
                  }}
                />
              ) : (
                <InputNoFormik
                  title="Custom Session"
                  withTitle
                  placeholder="Type your custom session"
                  handleChange={(e) =>
                    setSession({ value: e.target.value, label: e.target.value })
                  }
                />
              )}

              <div className="flex gap-2 items-center text-[12px] ">
                <input
                  className=" cursor-pointer"
                  type="checkbox"
                  checked={customSession}
                  onChange={() => {
                    setCustomSession((prev) => !prev);
                    setSession({});
                  }}
                />

                <p>Input a custom academic session.</p>
              </div>

              <div className="flex gap-2 items-center text-[12px] ">
                <input
                  className=" cursor-pointer"
                  type="checkbox"
                  checked={!disableButton}
                  onChange={() => setDisableButton((prev) => !prev)}
                />

                <p>
                  By entering new academic session it means you are moving to a
                  new session all unsaved data’s will be lost and cannot be
                  retrived
                </p>
              </div>

              <Button
                disabled={disableButton || session?.value === undefined}
                handleSubmit={handleSubmit}
                title="Proceed to New Session"
                className="lg:w-[30%] md:w-[50%]"
                isLoading={state.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SessionControl;

const CurrentSession = ({ session, isLoading }) => {
  if (isLoading) {
    return (
      <Skeleton
        count={1}
        height={30}
        containerClassName="w-[250px] sm:w-full"
      />
    );
  }
  return (
    <div className="flex justify-between w-[250px] sm:w-full p-2 bg-white">
      <p>Current Session:</p>
      <p>{session}</p>
    </div>
  );
};
