import PictureCardView from "./PictureCardView";
import { parentData, schoolData, studentData } from "../../utils";

const Features = () => {
  const featuresLabelBg = "/images/features-bg.png";
  return (
    <div className="sm:mt-6">
      <div
        style={{
          backgroundImage: `url(${featuresLabelBg})`,
        }}
        className="h-[100px] bg-no-repeat bg-center bg-cover sm:bg-contain sm:h-[57px] bg-[#5E91C1] flex items-center justify-center font-bold text-[50px] sm:text-[26px] text-primary"
      >
        Features
      </div>

      <PictureCardView
        title={parentData.title}
        variant={parentData.variant}
        features={parentData.features}
        imagePath={parentData.imagePath}
      />

      <PictureCardView
        title={schoolData.title}
        variant={schoolData.variant}
        features={schoolData.features}
        imagePath={schoolData.imagePath}
      />
      <PictureCardView
        title={studentData.title}
        variant={studentData.variant}
        features={studentData.features}
        imagePath={studentData.imagePath}
      />
    </div>
  );
};

export default Features;
