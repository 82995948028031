/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useRegisterMutation } from "../../Network/Auth/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRegistration } from "../../Store/AppSlice";

const useRegistration = () => {
  const [register, result] = useRegisterMutation();
  const dispatch = useDispatch();
  const [shortName, setShortName] = useState("");

  useEffect(() => {
    if (result?.isError) {
      if (result?.error?.status === 400)
        toast.error(result?.error?.data?.message);
      else {
        toast.error("Something went wrong!");
      }
    }
    if (result?.isSuccess) {
      toast.success("Successfull");
      dispatch(
        setRegistration({
          data: shortName,
          success: true,
        }),
      );
    }
  }, [result?.isError, result?.isSuccess]);

  const handleRegister = (values) => {
    setShortName(values?.school?.shortName);
    register(values);
  };

  return {
    handleRegister,
    loading: result.isLoading,
  };
};

export default useRegistration;
