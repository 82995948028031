import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  MdOutlinePublishedWithChanges,
  MdOutlineUnpublished,
} from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";

import { PopOver, PopOverList } from "Component/PopOver";
import { ActionTriggerIcon } from "Component/Table";

const Actions = ({
  data,
  setSelectedItems,
  setOpenEdit,
  setOpenConfirmBox,
  setOpenConfirmDelete,
  handleOpenView,
}) => {
  return (
    <PopOver
      className="w-[150px] text-[12px]"
      header={<ActionTriggerIcon />}
      content={
        <PopOverList
          className="px-2"
          items={[
            {
              name: data?.isPublished ? "Un Publish" : "Publish",
              icon: data?.isPublished ? (
                <MdOutlineUnpublished size={16} />
              ) : (
                <MdOutlinePublishedWithChanges size={16} />
              ),
              onClick: () => {
                setSelectedItems(data);
                setOpenConfirmBox(true);
              },
            },

            {
              name: "View",
              icon: <IoEyeOutline size={16} />,
              onClick: () => {
                handleOpenView(data);
              },
            },
            {
              name: "Edit",
              icon: <FiEdit size={16} />,
              onClick: () => {
                setSelectedItems(data);
                setOpenEdit(true);
              },
            },
            {
              name: "Delete",
              icon: <RiDeleteBinLine size={16} />,
              onClick: () => {
                setSelectedItems(data);
                setOpenConfirmDelete(true);
              },
              style: "text-red-600",
            },
          ]}
        />
      }
    />
  );
};

export default Actions;
