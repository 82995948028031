import { useState } from "react";
import toast from "react-hot-toast";
import { toast as toastify } from "react-toastify";

import * as Yup from "yup";
import { genderList } from "Utils/constants";
import { useCreateApplicantsMutation } from "Network/applicants";
import useUploadFileHooks from "../../../../hooks/File/useUploadFileHooks";
import { transformClassOption } from "Utils/transform";

export const applicantValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required"),
  dob: Yup.date().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string(),
  classId: Yup.string().required("Class ID is required"),
  applicationId: Yup.string().required("Application ID is required"),
  medicalInfo: Yup.string(),
  photo: Yup.string(),
  supportingDocuments: Yup.array(),
  parentOrGuardianInfo: Yup.object().shape({
    fullName: Yup.string().required("Full name of parent/guardian is required"),
    relationShip: Yup.string().required("Relationship is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  }),
});

const useCreateApplicants = ({ application, selectedClass }) => {
  const [createApplicants, applicantsState] = useCreateApplicantsMutation();

  const [selectedGender, setSelectedGender] = useState(genderList[0]);

  const selectedApplication = {
    value: application?._id,
    label: `${application?.session}: ${application?.description}`,
  };

  const transformedSelectedClass = transformClassOption([selectedClass])[0];

  const { resetFile } = useUploadFileHooks();

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: selectedGender?.value,
    address: "",
    classId: selectedClass?._id,
    applicationId: application?._id,
    medicalInfo: "",
    photo: undefined,
    supportingDocuments: [],
    parentOrGuardianInfo: {
      fullName: "",
      relationShip: "",
      phone: "",
      email: "",
    },
  };

  const handleSubmit = (values, actions) => {
    const data = {
      ...values,
      sectionId: application?.section?._id,
      categoryId: selectedClass?.category?._id,
      address: values?.address || undefined,
      middleName: values.middleName || undefined,
      medicalInfo: values.medicalInfo || undefined,
    };

    createApplicants({ data, schoolId: application?.schoolId })
      .unwrap()
      .then((_) => {
        actions.resetForm();
        toastify.success("Application created");
        resetFile();
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return {
    selectedGender,
    setSelectedGender,
    selectedApplication,
    selectedClass,
    transformedSelectedClass,
    initialValues,
    validationSchema: applicantValidationSchema,
    handleSubmit,
    applicantsState,
  };
};

export default useCreateApplicants;
