import { IoEyeOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

import { PopOver, PopOverList } from "Component/PopOver";
import { ActionTriggerIcon } from "Component/Table";
import useGetApplicantsFilters from "../hooks/useGetApplicantsFilters";
import useApplicantStore from "../hooks/useApplicantStore";
import { useNavigate, useParams } from "react-router-dom";
import { admissionRoute } from "Utils/navigationRoutes";

const Actions = ({ data }) => {
  const navigate = useNavigate();
  const params = useParams();

  const { setOpenConfirmDelete, setOpenEdit } = useGetApplicantsFilters();

  const { setApplicants } = useApplicantStore();
  return (
    <PopOver
      className="w-[150px] text-[12px]"
      header={<ActionTriggerIcon />}
      content={
        <PopOverList
          className="px-2"
          items={[
            {
              name: "View",
              icon: <IoEyeOutline size={16} />,
              onClick: () =>
                navigate(
                  `/${params.shortName}/${admissionRoute.applicants}/${data._id}`,
                ),
            },

            {
              name: "Edit",
              icon: <FiEdit size={16} />,
              onClick: () => {
                setOpenEdit(true);
                setApplicants(data);
              },
            },
            {
              name: "Delete",
              icon: <RiDeleteBinLine size={16} />,
              onClick: () => {
                setOpenConfirmDelete(true);
                setApplicants(data);
              },
              style: "text-red-600",
            },
          ]}
        />
      }
    />
  );
};

export default Actions;
