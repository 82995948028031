import { useEffect } from "react";
import { Formik } from "formik";
import PreLayout from "Component/Layout/PreLayout";
import Button from "Component/Forms/Button";
import Input from "Component/Forms/Input";
import * as Yup from "yup";
import { useParams } from "react-router";
import { useForgotPasswordMutation } from "Network/Auth/password";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const params = useParams();
  const [sendForgotPassword, forgotPasswordState] = useForgotPasswordMutation();

  useEffect(() => {
    if (forgotPasswordState.isSuccess) toast.success("Success");
    if (forgotPasswordState.isError) {
      if (forgotPasswordState.error.status === 404) {
        toast.error("Incorrect information");
      } else {
        toast.error(forgotPasswordState.error.data.message);
      }
    }
  }, [forgotPasswordState.isLoading]);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = (values) => {
    sendForgotPassword({
      ...values,
      schoolShortName: params.shortName,
    });
  };

  return (
    <PreLayout title={"Forget password"} className="max-w-[30vw] sm:max-w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
            {forgotPasswordState.isUninitialized ||
            !forgotPasswordState.isSuccess ? (
              <div className="flex flex-col gap-4 w-full">
                <p className="text-center text-[14px] text-primary font-semibold cursor-pointer">
                  Enter your email address to reset your password
                </p>

                <Input
                  withTitle
                  title="Email"
                  placeholder="Enter email"
                  name="email"
                />

                <Button
                  isLoading={forgotPasswordState.isLoading}
                  handleSubmit={props.handleSubmit}
                  title="Reset"
                  className=""
                />
              </div>
            ) : (
              <div className="flex flex-col items-center gap-6">
                <p className="text-center text-[14px]">
                  If account exist with this email, a password reset link has
                  been send to it.
                </p>

                {!forgotPasswordState.isLoading ? (
                  <p
                    onClick={props.handleSubmit}
                    className="cursor-pointer text-center text-[14px] text-primary font-semibold"
                  >
                    Resend Link?
                  </p>
                ) : (
                  <p className="text-center text-[14px] text-primary font-semibold">
                    ...
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </Formik>
    </PreLayout>
  );
};

export default ForgetPassword;
