import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppToast = () => {
  return (
    <ToastContainer
      progressStyle={{ backgroundColor: "black" }}
      toastStyle={{ fontSize: "14px" }}
    />
  );
};

export default AppToast;
