import FeaturesCard from "./FeaturesCard";
import PictureCard from "./PictureCard";
import cn from "Utils/cn";

const PictureCardView = ({ title, variant, features, imagePath }) => {
  return (
    <div className="flex flex-col items-center w-[75%] sm:w-[95%] mt-16 sm:mt-6 mx-auto gap-7 sm:gap-3">
      <p className="text-primary text-[50px] sm:text-[18px] font-semibold">
        {title}
      </p>

      <div
        className={cn(
          "flex justify-between sm:gap-3 w-full items-center",
          variant === "right" && "flex-row-reverse",
        )}
      >
        <div className="flex flex-col gap-7 sm:gap-3">
          {features.map((feature) => (
            <FeaturesCard
              title={feature?.title}
              text={feature?.text}
              variant={variant}
            />
          ))}
        </div>
        <PictureCard imagePath={imagePath} text={title} />
      </div>
    </div>
  );
};

export default PictureCardView;
