import Avatar from "Component/Avatar";
import cn from "Utils/cn";

const TestimonialCard = ({ title, image, text, variant, key }) => {
  const variantType = "secondary";
  return (
    <div
      key={key}
      className={cn(
        "h-[334px] sm:h-[178px] w-[345px] sm:w-[184px] relative bg-[#5E91C1]",
        variant === variantType && "bg-primary",
      )}
    >
      <div
        className={cn(
          "h-[334px] sm:h-[178px] w-[345px] sm:w-[184px] absolute left-8 sm:left-3 top-10 sm:top-3 flex flex-col gap-3 px-4 sm:px-1 bg-primary text-white",
          variant === variantType && "bg-[#5E91C1] text-primary",
        )}
      >
        <div className="flex gap-5 sm:gap-3 items-end sm:items-center mt-[-20px] sm:mt-[-2px]">
          <Avatar
            image={image}
            className={cn(
              "w-[90px] sm:w-[48px] h-[90px] sm:h-[48px] border-[3px]",
              variant === variantType && "border-[#D10409]",
            )}
          />
          <p className="text-[22px] sm:text-[12px] font-semibold mb-[10px] sm:mb-0">
            {title}
          </p>
        </div>
        <p className="text-[20px] sm:text-[10px] italic text-center mx-2">
          {text}
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;
