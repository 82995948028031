import MainLayout from "Component/Layout/MainLayout";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import Card from "./component/Card";
import CardLayout from "./component/CardLayout";
import { Formik } from "formik";
import * as Yup from "yup";
import ConfirmDelete from "Component/ConfirmDelete";
import OneFieldUpdate from "./component/OneFieldUpdate";

import useSection from "./hooks/section/useSection";

const Section = () => {
  const {
    sections,
    createSectionState,
    deleteSectionState,
    editSectionState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
  } = useSection();

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Section name is required"),
  });

  return (
    <MainLayout title="Create Section">
      <div className="flex flex-col gap-8">
        <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <div className="flex flex-col gap-4 w-full">
                <Input
                  withTitle
                  title="Section"
                  placeholder="Example Junior Senior etc"
                  name="name"
                />

                <Button
                  title="Create"
                  className="w-[100px] py-2"
                  handleSubmit={props.handleSubmit}
                  isLoading={createSectionState.isLoading}
                />
              </div>
            )}
          </Formik>
        </div>

        <CardLayout title="Sections List" isLoading={sections.isFetching}>
          {sections.isSuccess && (
            <div className="flex gap-6 flex-wrap">
              {sections.data.map((sec, i) => (
                <Card
                  title={sec?.name}
                  key={i}
                  onDelete={() => {
                    setSelectedItem(sec);
                    setConfirmDelete(true);
                  }}
                  onEdit={() => {
                    setSelectedItem(sec);
                    setOpenUpdateModal(true);
                  }}
                />
              ))}
            </div>
          )}
        </CardLayout>
      </div>
      {confirmDelete && (
        <ConfirmDelete
          openConfirmDelete={confirmDelete}
          setOpenConfirmDelete={setConfirmDelete}
          title="Section"
          deleteName={selectedItem?.name}
          isLoading={deleteSectionState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      {openUpdateModal && (
        <OneFieldUpdate
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          title="Section"
          label="Section Name"
          value={selectedItem?.name}
          handleUpdate={handleUpdate}
          isLoading={editSectionState.isLoading}
        />
      )}
    </MainLayout>
  );
};

export default Section;
