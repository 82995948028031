import { configureStore } from "@reduxjs/toolkit";
import AppReducer from "./AppSlice";
import api from "../Network/apiSlice";

export const Store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    appSlice: AppReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
