import { Formik } from "formik";
import * as Yup from "yup";

import MainLayout from "Component/Layout/MainLayout";
import Input, { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import Card from "./component/Card";
import CardLayout from "./component/CardLayout";
import ConfirmDelete from "Component/ConfirmDelete";

import useAttachment from "./hooks/attachment/useAttachment";
import OneFieldUpdate from "./component/OneFieldUpdate";

const AttachmentDocument = () => {
  const {
    attachments,
    createAttachmentState,
    deleteAttachmentState,
    editAttachmentState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
    handleUpload,
    uploadState,
  } = useAttachment();

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("File name is required"),
  });

  return (
    <MainLayout title="Upload Attachment Document">
      <div className="flex flex-col gap-8">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
              <Input
                withTitle
                title="File Name"
                placeholder="Enter file name"
                name="name"
              />
              <InputNoFormik
                withTitle
                title="Upload Document"
                placeholder="Example Birth Certificate etc"
                type="file"
                isLoading={uploadState.isLoading}
                handleChange={(e) => {
                  handleUpload(e.target.files[0]);
                }}
              />

              <div className="flex flex-col gap-1 text-[#08355C] text-[12px]">
                <p>
                  ** All documents uploaded in this section will can be used as
                  extra attach files
                </p>
                <p>
                  *** only pdf files are supported and should not exceed 300kb
                </p>
              </div>

              <Button
                disabled={!uploadState?.data}
                title="Upload"
                className="w-[100px] py-2"
                handleSubmit={props.handleSubmit}
                isLoading={createAttachmentState.isLoading}
              />
            </div>
          )}
        </Formik>

        <CardLayout title="Documents List" isLoading={attachments.isFetching}>
          {attachments.isSuccess && (
            <div className="flex gap-6 flex-wrap">
              {attachments.data.map((itm, i) => (
                <Card
                  title={itm?.name}
                  key={i}
                  onDelete={() => {
                    setSelectedItem(itm);
                    setConfirmDelete(true);
                  }}
                  onEdit={() => {
                    setSelectedItem(itm);
                    setOpenUpdateModal(true);
                  }}
                />
              ))}
            </div>
          )}
        </CardLayout>
      </div>
      {confirmDelete && (
        <ConfirmDelete
          openConfirmDelete={confirmDelete}
          setOpenConfirmDelete={setConfirmDelete}
          title="Attachment Document"
          isLoading={deleteAttachmentState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      {openUpdateModal && (
        <OneFieldUpdate
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          title="Attachment Document"
          label="File Name"
          value={selectedItem?.name}
          handleUpdate={handleUpdate}
          isLoading={editAttachmentState.isLoading}
        />
      )}
    </MainLayout>
  );
};

export default AttachmentDocument;
