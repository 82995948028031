import { useState } from "react";
import { useGetSectionsQuery } from "Network/classes/section";
import { useGetCategoriesQuery } from "Network/classes/category";
import { useCreateClassMutation } from "Network/classes/class";
import { toast } from "react-toastify";

const useClass = () => {
  const sections = useGetSectionsQuery();
  const categories = useGetCategoriesQuery();
  const [createClass, classState] = useCreateClassMutation();

  const [selectedSection, setSelectedSection] = useState({
    label: "Select Section",
    value: null,
  });
  const [selectedCategory, setSelectedCategory] = useState({
    label: "Select Category",
    value: null,
  });

  const handleSubmit = (values, actions) => {
    const data = values;
    if (selectedCategory.value !== null) {
      data.categoryId = selectedCategory.value;
    } else {
      delete data.categoryId;
    }

    createClass(data)
      .unwrap()
      .then((res) => {
        actions.resetForm();
        toast.success("Class created");
        setSelectedCategory({
          label: "Select Category",
          value: null,
        });
        setSelectedSection({
          label: "Select Section",
          value: null,
        });
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error("Class already exists");
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    sections,
    categories,
    classState,
    selectedSection,
    selectedCategory,
    setSelectedSection,
    setSelectedCategory,
    handleSubmit,
  };
};

export default useClass;
