import { RxDashboard } from "react-icons/rx";
import MobileNavItem, { DropDownMobileNavItem } from "./MobileNavItem";
import { MdCancel } from "react-icons/md";
import { FiUsers, FiSettings } from "react-icons/fi";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { BsListCheck } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { setToggleMobileNav } from "../../../Store/AppSlice";
import { FcDataConfiguration } from "react-icons/fc";
import { TbCurrencyNaira } from "react-icons/tb";
import { generalSettingsRoute, admissionRoute } from "Utils/navigationRoutes";

const MobileAddNav = () => {
  const dispatch = useDispatch();

  return (
    <div
      className="flex flex-col gap-5 overflow-y-auto h-full pb-3"
      style={{ scrollbarWidth: "thin" }}
    >
      <MdCancel
        onClick={() => dispatch(setToggleMobileNav(false))}
        size={30}
        className="self-end"
      />
      <div className="flex flex-col gap-3">
        <MobileNavItem
          Icon={RxDashboard}
          text={"Dashboard"}
          active={admissionRoute.dashboard}
        />
        <MobileNavItem
          Icon={FiUsers}
          text={"Applicants"}
          active={admissionRoute.applicants}
        />
        <MobileNavItem
          Icon={HiOutlineClipboardDocumentCheck}
          text={"Applications"}
          active={admissionRoute.applications}
        />
        <MobileNavItem
          Icon={TbCurrencyNaira}
          text={"Payment Verification"}
          active={admissionRoute.paymentVerification}
        />

        <DropDownMobileNavItem
          Icon={FcDataConfiguration}
          text={"Configurations"}
          active={admissionRoute.settings}
          items={[
            {
              name: "Admission template",
              link: admissionRoute.admissionTemplate,
            },

            {
              name: "Supporting Document",
              link: admissionRoute.supportingDocument,
            },

            {
              name: "Entry Requirement",
              link: admissionRoute.admissionEntryRequirement,
            },
            {
              name: "Applicant Form Builder",
              link: admissionRoute.applicantFormBuilder,
            },
          ]}
        />

        <DropDownMobileNavItem
          Icon={FiSettings}
          text={"General Settings"}
          active={generalSettingsRoute.mainPath}
          items={[
            {
              name: "School profile",
              link: generalSettingsRoute.schoolProfile,
            },
            {
              name: "Bank Control",
              link: generalSettingsRoute.bank,
            },

            {
              name: "Session control",
              link: generalSettingsRoute.session,
            },
            {
              name: "Section",
              link: generalSettingsRoute.section,
            },
            {
              name: "Category",
              link: generalSettingsRoute.category,
            },
            {
              name: "Classes",
              link: generalSettingsRoute.schoolClass,
            },
            {
              name: "Attachment Document",
              link: generalSettingsRoute.attachmentDocument,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default MobileAddNav;
