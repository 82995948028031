import * as SwitchPrimitive from "@radix-ui/react-switch";

import cn from "../Utils/cn";

const Switch = ({ className, children, ...props }) => {
  return (
    <SwitchPrimitive.Root
      className={cn(
        "w-[42px] h-[25px] bg-slate-200 rounded-full relative border-1 data-[state=checked]:bg-primary outline-none cursor-default",
        className,
      )}
      id="airplane-mode"
      style={{ "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)" }}
      {...props}
    >
      {children}
    </SwitchPrimitive.Root>
  );
};

const Thumb = ({ ...props }) => {
  return (
    <SwitchPrimitive.Thumb
      className="block w-[21px] h-[21px] bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]"
      {...props}
    />
  );
};

export default { Root: Switch, Thumb };
