import React from "react";
import schoolLogo from "../../../../../Assets/school.jpeg";
import pic from "../../../../../Assets/child.png";

const Template1 = () => {
  return (
    <div className="font-sans  m-auto mt-5 bg-white p-5 w-[80%] sm:w-[100%] border-1 sm:text-[12px] ">
      <div className="header flex justify-center items-center">
        <img src={schoolLogo} alt="" className="w-20 h-20 sm:w-15 sm:h-15" />
        <div>
          <h1 className="text-2xl text-center sm:text-12 font-semibold">
            M.A.S ACADEMY SANGERE
          </h1>
          <div className="header-info text-center">
            <p>Address: Numan Road, OPP FGGC Yola, Yola North Adamawa State</p>
            <p>Phone: (+234) 8140409672</p>
          </div>
        </div>
      </div>

      <div className="watermark absolute top-[75%] left-[65%] transform -translate-x-1/2 -translate-y-1/2 opacity-10 z-0">
        <img
          src={schoolLogo}
          alt="School Logo"
          className="w-[80%] h-[50%] align-middle"
        />
      </div>

      <div className="content relative z-10 mb-5">
        <div className="ref-date flex justify-between sm:flex-col">
          <p>Ref: ____________________</p>
          <p>Your Ref: ___________________</p>
          <p>
            Date: <span>12th Sept 2024</span>
          </p>
        </div>
        <hr />
        <div className="info-with-passport-container flex justify-between">
          <div>
            <p className="font-bold">Muhammad Shariff Halidu</p>
            <p>NIA/APN0001</p>
            <p>Jimeta, Yola North Adamawa State</p>
          </div>
          <div className="passport-photograph">
            <img src={pic} alt="" className="w-15 h-15 sm:w-10 sm:h-10" />
          </div>
        </div>
        <p className="Admission-offer font-bold underline text-center">
          OFFER OF PROVISIONAL ADMISSION INTO: JUNIOR SECTION FOR 2023/2024
          ACADEMIC SESSION
        </p>
        <p>
          I am pleased to inform you that you have been offered a Provisional
          Admission at Noble Intellects Academy for the 2023/2024 Academic
          session as follows:
        </p>
        <div className="registration-material mb-5 mt-5">
          {/* <h2 className="text-xl font-bold">ENTRY REQUIREMENTS</h2> */}
          <ol className="list-disc pl-5">
            <li>Section:Junior</li>
            <li>Class: JSS 1</li>
          </ol>
        </div>
        <p>
          If you accept this offer, you are required to commence registration
          and resume classes as from{" "}
          <span className="font-bold">12th September, 2024</span> which is the
          resumption date.
        </p>
      </div>

      <div className="registration-material mb-5">
        <h2 className="text-xl font-bold">ENTRY REQUIREMENTS</h2>
        <ol className="list-disc pl-5">
          <li>
            Payment of fees should be made in the school premises or done via
            the bank (Online transfers) with evidence of payments given to the
            admin.
          </li>
          <li>2 pairs of black colored socks</li>
          <li>1 pair of black shoe or sandals for classes</li>
          <li>One Big Sachets of Detergent ( O M O )</li>
          <li>One Disinfectants (D etol )</li>
          <li>Copy of this admission letter</li>
        </ol>
      </div>

      <div className="mt-4">
        <p>
          Please find attached the school rules and regulations, as you guide
          your wards towards the compliance of these rules.
        </p>
      </div>
      <div className="mt-4">
        <p>
          While thanking you for your support and continued patronage, please
          accept the assurance of our highest esteem.{" "}
        </p>
      </div>
      <div className="mt-4">
        <p>Thank you very much and congratulations.</p>
      </div>

      <div className="footer text-center">
        <div>
          <img src="sign.png" alt="Signature" className="signature w-25 h-12" />
        </div>
        <p>Accept my congratulations</p>
        <p>Mr. Ibrahim Mustapha</p>
        <p>Director Noble Intellects Academy</p>
      </div>
    </div>
  );
};

export default Template1;
