import MainLayout from "Component/Layout/MainLayout";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import Card from "./component/Card";
import CardLayout from "./component/CardLayout";
import { Formik } from "formik";
import * as Yup from "yup";
import ConfirmDelete from "Component/ConfirmDelete";
import OneFieldUpdate from "./component/OneFieldUpdate";

import useSupportingDocument from "./hooks/supportingDocument/useSupportingDocument";

const SupportingDocument = () => {
  const {
    documents,
    createDocumentState,
    deleteDocumentState,
    editDocumentState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
  } = useSupportingDocument();

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Document name is required"),
  });

  return (
    <MainLayout title="Create Supporting Document">
      <div className="flex flex-col gap-8">
        <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <div className="flex flex-col gap-4 w-full">
                <Input
                  withTitle
                  title="Document"
                  placeholder="Example Birth Certificate etc"
                  name="name"
                />

                <Button
                  title="Create"
                  className="w-[100px] py-2"
                  handleSubmit={props.handleSubmit}
                  isLoading={createDocumentState.isLoading}
                />
              </div>
            )}
          </Formik>
        </div>

        <CardLayout title="Documents List" isLoading={documents.isFetching}>
          {documents.isSuccess && (
            <div className="flex gap-6 flex-wrap">
              {documents.data.map((itm, i) => (
                <Card
                  title={itm?.name}
                  key={i}
                  onDelete={() => {
                    setSelectedItem(itm);
                    setConfirmDelete(true);
                  }}
                  onEdit={() => {
                    setSelectedItem(itm);
                    setOpenUpdateModal(true);
                  }}
                />
              ))}
            </div>
          )}
        </CardLayout>
      </div>
      {confirmDelete && (
        <ConfirmDelete
          openConfirmDelete={confirmDelete}
          setOpenConfirmDelete={setConfirmDelete}
          title="Supporting Document"
          isLoading={deleteDocumentState.isLoading}
          handleDelete={handleDelete}
        />
      )}

      {openUpdateModal && (
        <OneFieldUpdate
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          title="Supporting Document"
          label="Document Name"
          value={selectedItem?.name}
          handleUpdate={handleUpdate}
          isLoading={editDocumentState.isLoading}
        />
      )}
    </MainLayout>
  );
};

export default SupportingDocument;
