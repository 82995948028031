import { create } from "zustand";

const useGetApplicantsFilters = create((set) => ({
  page: 1,
  search: "",
  setPage: (page) => set({ page }),
  setSearch: (search) => set({ search }),

  openConfirmDelete: false,
  openEdit: false,

  setOpenConfirmDelete: (openConfirmDelete) => set({ openConfirmDelete }),
  setOpenEdit: (openEdit) => set({ openEdit }),

  handleSearch: (text) => {
    set((state) => {
      if (text.length >= 3 || text === "") {
        return { search: text };
      }
      return state;
    });
  },
}));

export default useGetApplicantsFilters;
