import { uploadFile } from "../../Network/Auth/profile";
import { IMAGE_SIZE, filesAllowed } from "../../Utils/constants";
import { toast } from "react-toastify";
import readableFileSize from "../../Utils/readableFileSize";
import useUploadFileHooks from "./useUploadFileHooks";

const useUploadFile = ({ filesTypeListToAllow = filesAllowed } = {}) => {
  const {
    setIsLoading,
    isLoading,
    setData,
    data,
    exceedSize,
    setExceedSize,
    selectedImage,
    setSelectedImage,
    setFileNotAllowed,
    fileNotAllowed,
    resetFile,
  } = useUploadFileHooks();

  const handleUpload = (fileUploaded, size) => {
    const fileSize = size ?? IMAGE_SIZE;

    if (fileUploaded.size > fileSize) {
      toast.error(`File size exceeds ${readableFileSize(fileSize)}`);
      setExceedSize(true);
      return;
    }

    if (!filesTypeListToAllow.includes(fileUploaded.type.split("/")[1])) {
      toast.error("File type not allowed");
      setFileNotAllowed(true);
      return;
    }

    setExceedSize(false);
    setFileNotAllowed(false);
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("file", fileUploaded);
    uploadFile(formdata)
      .then((val) => {
        setIsLoading(false);
        setData(val?.data);
      })
      .catch((_) => {
        setIsLoading(false);
      });
  };

  return [
    handleUpload,
    {
      isLoading,
      data,
      exceedSize,
      selectedImage,
      setSelectedImage,
      resetFile,
      fileNotAllowed,
    },
  ];
};

export const useAsyncUploadFile = ({
  filesTypeListToAllow = filesAllowed,
} = {}) => {
  const { setIsLoading, isLoading, setExceedSize, setFileNotAllowed } =
    useUploadFileHooks();

  const handleUpload = async (fileUploaded, size) => {
    const fileSize = size ?? IMAGE_SIZE;

    if (fileUploaded.size > fileSize) {
      toast.error(`File size exceeds ${readableFileSize(fileSize)}`);
      setExceedSize(true);
      return undefined;
    }

    if (!filesTypeListToAllow.includes(fileUploaded.type.split("/")[1])) {
      toast.error("File type not allowed");
      setFileNotAllowed(true);
      return undefined;
    }

    setExceedSize(false);
    setFileNotAllowed(false);
    setIsLoading(true);

    try {
      const formdata = new FormData();
      formdata.append("file", fileUploaded);
      const response = await uploadFile(formdata);
      setIsLoading(false);
      return response;
    } catch (error) {
      toast.error("Fail to upload file");
      setIsLoading(false);
    }

    return undefined;
  };

  return [
    handleUpload,
    {
      isLoading,
    },
  ];
};

export default useUploadFile;
