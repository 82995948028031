/* eslint-disable */

export const flattenRoutes = (routeConfig) => {
  const result = {
    mainPath: routeConfig.mainPath, // Add the mainPath directly
  };

  function buildPaths(currentConfig, currentPath = "") {
    // Append the mainPath to the current path if it exists
    if (currentConfig.mainPath) {
      currentPath += currentConfig.mainPath;
    }

    // Iterate through the childPath object if it exists
    if (currentConfig.childPath) {
      for (const key in currentConfig.childPath) {
        const child = currentConfig.childPath[key];

        // Handle the case where the child is a string (direct route)
        if (typeof child === "string") {
          result[key] = `${currentPath}/${child}`;
        }
        // Handle the case where the child is an object (nested route)
        else if (typeof child === "object" && child.main) {
          const newPath = `${currentPath}/${child.main}`;
          result[key] = newPath;
          // Recursively handle nested paths
          buildPaths(child, newPath);
        }
      }
    }
  }

  buildPaths(routeConfig);
  return result;
};

export const replaceWhiteSpaces = (str) => {
  return str.trim().replace(/\s+/g, "-");
};
