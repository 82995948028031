import { useState, useCallback } from "react";
import MainLayout from "Component/Layout/MainLayout";
import avatar from "Assets/avatar.png";
import { TfiEmail } from "react-icons/tfi";
import { FaPhoneAlt } from "react-icons/fa";
import { useFormik } from "formik";
import { InputNoFormik } from "Component/Forms/Input";
import FileUploader from "Component/Forms/FileUploader";
import Button from "Component/Forms/Button";
import ChangePassword from "./ChangePassword";
import Select from "Component/Forms/Select";
import { profileInitialValues, profileValidationSchema } from "./validators";
import { genderList, roles } from "Utils/constants";
import useUploadFile from "hooks/File/useUploadFile";
import { useEditProfile, useGetProfile } from "./hooks";

const Profile = () => {
  const [openChangePassword, setOpenChangepassword] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [handleUpload, uploadState] = useUploadFile();
  const [handleEdit, editState] = useEditProfile(setDisabled);

  const getRoleText = useCallback((role) => {
    switch (role) {
      case roles.SUPER_ADMIN:
        return "Admin";
      default:
        return "";
    }
  }, []);

  const formik = useFormik({
    initialValues: profileInitialValues,
    validationSchema: profileValidationSchema,
    onSubmit: (values) => {
      handleEdit({ ...values, image: uploadState?.data?.src });
    },
  });

  const { profileState, gender, setGender, resetForm } = useGetProfile(formik);

  const handleResetForm = () => {
    setDisabled(true);
    formik.resetForm();
    resetForm();
    setGender({
      value: null,
      label: "Choose a gender",
    });
  };

  return (
    <MainLayout isLoading={profileState.isLoading}>
      <div>
        <div className="font-semibold p-3 pb-5">
          <span>Manage Profile</span>
        </div>
        <div className="flex sm:justify-center sm:flex-col gap-5">
          <div className="bg-white p-5 flex flex-col  justify-center items-center h-[300px]  min-w-68 md:w-[356px] rounded-sm shadow-md">
            <img
              src={
                profileState?.data?.image ? profileState?.data?.image : avatar
              }
              alt=" />"
              className="h-[100px] w-[100px] rounded-full bg-white border-1 "
            />
            <div className="pt-2 mt-[10px]">
              <div>
                <span className="font-bold ">
                  {profileState?.data?.firstName} {profileState?.data?.lastName}{" "}
                  ({getRoleText(profileState?.data?.role)})
                </span>
              </div>
              <div className="flex mt-[15px] gap-2">
                <span className="pt-[4px]">
                  <TfiEmail size={13} />
                </span>
                <span className="text-slate-900 text-[14px]">
                  {profileState?.data?.email}
                </span>
              </div>
              {profileState?.data?.phone && (
                <div className="flex mt-[5px] gap-2">
                  <span className="pt-[4px]">
                    <FaPhoneAlt size={12} />
                  </span>
                  <span className="text-slate-900 text-[14px]">
                    {profileState?.data?.phone}
                  </span>
                </div>
              )}
            </div>
            <div className="mt-[15px]">
              <span
                onClick={() => setOpenChangepassword(true)}
                className="text-[12px] text-white bg-primary cursor-pointer rounded-sm p-2"
              >
                Change Password
              </span>
            </div>
          </div>

          <div className="w-[100%]">
            <div className="flex  flex-col px-6 py-6 bg-white">
              <div className=" grid grid-cols-2 sm:grid-cols-1 sm:gap-2 gap-3">
                <InputNoFormik
                  className="sm:w-[100%] !gap-0 font-thin"
                  withTitle
                  title="First Name"
                  placeholder="Enter first Name"
                  value={formik.values.firstName}
                  name="firstName"
                  handleChange={formik.handleChange}
                  showError
                  error={formik.errors.firstName}
                  touched={formik.touched.firstName}
                  disabled={disabled}
                />
                <InputNoFormik
                  className="sm:w-[100%] !gap-0"
                  withTitle
                  title="Last Name"
                  placeholder="Enter Last Name"
                  value={formik.values.lastName}
                  name="lastName"
                  handleChange={formik.handleChange}
                  showError
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                  disabled={disabled}
                />
                <InputNoFormik
                  className="sm:w-[100%] !gap-0"
                  withTitle
                  title="Phone"
                  value={formik.values.phone}
                  name="phone"
                  placeholder="Enter Phone Number"
                  handleChange={formik.handleChange}
                  showError
                  error={formik.errors?.phone}
                  touched={formik.touched?.phone}
                  disabled={disabled}
                />
                <InputNoFormik
                  className="sm:w-[100%] !gap-0"
                  withTitle
                  title="Address"
                  placeholder="Enter Address"
                  value={formik.values.address}
                  name="address"
                  handleChange={formik.handleChange}
                  showError
                  error={formik.errors?.address}
                  touched={formik.touched?.address}
                  disabled={disabled}
                />
                <InputNoFormik
                  className="sm:w-[100%] !gap-0"
                  withTitle
                  title="Email"
                  placeholder="Enter Email"
                  handleChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  showError
                  error={formik.errors?.email}
                  touched={formik.touched?.email}
                  disabled={disabled}
                />

                <Select
                  label="Gender"
                  labelClass="text-[14px]"
                  mainContainerStyle={`w-full flex flex-col gap-0`}
                  className={`${formik.errors?.gender && formik.touched?.gender && "!border-[red] "}`}
                  data={genderList}
                  value={gender}
                  onChangeOption={(x) => {
                    formik.setFieldValue("gender", x?.value);
                    setGender(x);
                  }}
                  disabled={disabled}
                />
              </div>

              <div className="mt-[20px]">
                <span className="text-[14px]">Change Profile Pic</span>
                <FileUploader
                  exceedSize={uploadState.exceedSize}
                  disabled={disabled}
                  isLoading={uploadState.isLoading}
                  title=" Drag &amp; drop here or Click"
                  onFileUpload={(file) => handleUpload(file[0])}
                />
              </div>

              {disabled ? (
                <Button
                  className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                  title="Edit"
                  handleSubmit={() => setDisabled(false)}
                />
              ) : (
                <div className="flex gap-5">
                  <Button
                    className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                    title="Cancel"
                    variant="outline"
                    handleSubmit={handleResetForm}
                  />
                  <Button
                    isLoading={editState.isLoading}
                    className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                    title="Save"
                    handleSubmit={formik.handleSubmit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangepassword={setOpenChangepassword}
      />
    </MainLayout>
  );
};

export default Profile;
