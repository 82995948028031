import cn from "Utils/cn";

import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import PropTypes from "prop-types";
import capitalizeFirstLetter from "Utils/capitalizeFirstLetter";

const Card = ({ title, className, key, onEdit, onDelete }) => {
  return (
    <div
      key={key}
      className={cn(
        "flex items-center justify-between text-[14px] bg-white px-3 py-2 shadow-shift-shadow rounded-md min-h-[50px] gap-6 min-w-[120px]",
        className,
      )}
    >
      <p>{capitalizeFirstLetter(title)}</p>
      <div className="flex gap-3 text-[20px]">
        <FiEdit className="cursor-pointer" onClick={onEdit} />
        <RiDeleteBinLine className="cursor-pointer" onClick={onDelete} />
      </div>
    </div>
  );
};

export default Card;

Card.prototype = {
  title: PropTypes.string,
  className: PropTypes.string,
  key: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

Card.defaultProps = {
  title: "",
  className: "",
  key: 0,
  onEdit: () => {},
  onDelete: () => {},
};
