/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useCallback } from "react";
import { getNigeriaStates } from "geo-ng";

import { countryList } from "Utils/constants";
import capitalizeFirstLetter from "Utils/capitalizeFirstLetter";

const useCountry = (formikProps) => {
  const nigeriaStates = getNigeriaStates();
  const [countryValue, setCountryValue] = useState({
    value: null,
    label: "Choose a country",
  });
  const [stateValue, setStateValue] = useState({
    value: null,
    label: "Choose a state",
  });
  const [localGovernmentValue, setLocalGovernmentValue] = useState({
    value: null,
    label: "Choose a local government",
  });

  const [localGovernmentList, setLocalGovernmentList] = useState([]);
  const stateList = nigeriaStates.map((state) => {
    const stateName = capitalizeFirstLetter(state.name.toLocaleLowerCase());
    return { value: stateName, label: stateName };
  });

  const getLgas = useCallback(
    (selectedState) => {
      if (selectedState) {
        const state = nigeriaStates.filter(
          (st) =>
            st.name.toLocaleLowerCase() === selectedState?.toLocaleLowerCase(),
        );
        const lgas = state[0]?.lgas?.map((lga) => {
          const lgaName = capitalizeFirstLetter(lga.toLocaleLowerCase());
          return { value: lgaName, label: lgaName };
        });
        setLocalGovernmentList(lgas);

        return lgas;
      }
      return [];
    },
    [nigeriaStates],
  );

  useEffect(() => {
    if (formikProps.values.country) {
      const selectedCountry = countryList.find(
        (cn) => cn.value === formikProps.values.country,
      );
      if (selectedCountry) {
        setCountryValue(selectedCountry);
      }
    }

    if (formikProps.values.state) {
      const selectedState = stateList.find(
        (st) => st.value === formikProps.values.state,
      );
      if (selectedState) {
        setStateValue(selectedState);
        getLgas(selectedState.value); // Ensure LGAs are loaded when state changes
      }
    }

    if (formikProps.values.localGovernment) {
      const lgas = getLgas(formikProps.values.state);
      const selectedLga = lgas.find(
        (lg) => lg.value === formikProps.values.localGovernment,
      );
      if (selectedLga) {
        setLocalGovernmentValue(selectedLga);
      }
    }
  }, [
    formikProps.values.country,
    formikProps.values.state,
    formikProps.values.localGovernment,
    stateList,
    getLgas,
  ]);

  return {
    stateValue,
    countryValue,
    localGovernmentValue,
    setStateValue,
    setCountryValue,
    setLocalGovernmentValue,
    countryList,
    stateList,
    localGovernmentList,
  };
};

export default useCountry;
