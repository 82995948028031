import { Route } from "react-router-dom";

import AcademicsHome from "../Screens/AcademicScreens/Home";
import { navigationRoutes, academicRoute } from "Utils/navigationRoutes";

const AcademicRoutes = (
  <Route path={navigationRoutes.SHORTNAME}>
    <Route path={academicRoute.dashboard} element={<AcademicsHome />} />,
  </Route>
);

export default AcademicRoutes;
