import { useState } from "react";
import { toast } from "react-toastify";

import {
  useGetSectionsQuery,
  useGetSectionClassesQuery,
} from "Network/classes/section";
import { useGetSessionQuery } from "Network/Configurations/session";
import { useGetDocumentsQuery } from "Network/Configurations/supportingDocument";
import { useCreateApplicationMutation } from "Network/applications";
import { useGetEntryRequirementsQuery } from "Network/Configurations/admissionEntryRequirement";
import { useGetAttachmentsQuery } from "Network/Configurations/attachment";

const useCreateApplication = () => {
  const sections = useGetSectionsQuery();
  const documents = useGetDocumentsQuery();
  const session = useGetSessionQuery();
  const entryRequirements = useGetEntryRequirementsQuery();
  const attachments = useGetAttachmentsQuery();

  const [createApplication, applicationState] = useCreateApplicationMutation();

  const [selectedSection, setSelectedSection] = useState({
    label: "Select Section",
    value: null,
  });

  const classes = useGetSectionClassesQuery(selectedSection?.value, {
    skip: selectedSection?.value === null,
  });

  const [selectedPaymentMode, setSelectedPaymentMode] = useState({
    label: "Select Payment mode",
    value: null,
  });

  const [selectedHasEntranceExam, setSelectedHasEntranceExam] = useState({
    label: "Do you conduct entrance exam",
    value: null,
  });

  const [selectedClasses, setSelectedClasses] = useState([]);

  const [selectedDocument, setSelectedDocument] = useState([]);

  const [selectedRequirement, setSelectedRequirement] = useState([]);

  const [selectedAttachment, setSelectedAttachment] = useState([]);

  const handleSubmit = (values, actions) => {
    createApplication({ ...values, session: session?.data?.session })
      .unwrap()
      .then((res) => {
        actions.resetForm();
        toast.success("Application created");
        setSelectedClasses({
          label: "Select Category",
          value: null,
        });
        setSelectedSection({
          label: "Select Section",
          value: null,
        });
        setSelectedPaymentMode({
          label: "Select Payment mode",
          value: null,
        });
        setSelectedHasEntranceExam({
          label: "Do you conduct entrance exam",
          value: null,
        });
        setSelectedClasses([]);
        setSelectedDocument([]);
        setSelectedRequirement([]);
        setSelectedAttachment([]);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(
            "There is already an application for one of the selected class",
          );
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    sections,
    classes,
    documents,
    entryRequirements,
    applicationState,
    selectedSection,
    selectedClasses,
    selectedDocument,
    selectedRequirement,
    selectedPaymentMode,
    selectedHasEntranceExam,
    setSelectedSection,
    setSelectedClasses,
    setSelectedDocument,
    setSelectedRequirement,
    setSelectedPaymentMode,
    setSelectedHasEntranceExam,
    session: session?.data?.session,
    handleSubmit,

    selectedAttachment,
    setSelectedAttachment,
    attachments,
  };
};

export default useCreateApplication;
