import { useNavigate } from "react-router";

import Button from "Component/Forms/Button";
import { navigationRoutes } from "Utils/navigationRoutes";

const MidHero = () => {
  const imagePath = "/images/mid-hero.png";
  const navigate = useNavigate();
  return (
    <div
      className="w-full h-[400px] sm:h-[126px] mt-24 sm:mt-10 bg-primary border-1 px-60 sm:px-10 flex flex-col items-center justify-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${imagePath})`,
      }}
    >
      <p className="text-white font-bold text-center text-[30px] sm:text-[14px]">
        Signup to see how Softschool can transform your school management
      </p>

      <div className="flex gap-3 mt-5">
        <a href={process.env.REACT_APP_PARENT_PORTAL}>
          <Button
            title="Parent"
            variant="outline"
            className="border-[#5E91C1] text-[16px] sm:text-[8px] w-[130px] sm:w-[65px] h-[40px] sm:h-[23px] rounded-[5px] sm:rounded-sm text-white z-10"
          />
        </a>
        <Button
          title="School"
          className="bg-[#5E91C1] text-[16px] sm:text-[8px] w-[130px] sm:w-[65px] h-[40px] sm:h-[23px] rounded-[5px] sm:rounded-sm text-white z-10"
          handleSubmit={() => {
            navigate(navigationRoutes.SIGNUP);
          }}
        />
      </div>
    </div>
  );
};

export default MidHero;
