import api from "./apiSlice";
import { tags } from "../Utils/constants";
import { getSchoolId } from "./utils";

const endpoint = "payments";

const applicants = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplicantPayments: builder.query({
      query: ({
        page,
        search,
        sectionId,
        categoryId,
        classId,
        admissionStatus,
      }) => {
        const url = `/${endpoint}/school/${getSchoolId()}`;

        const params = { sectionId, categoryId, classId, admissionStatus };

        if (page !== undefined) {
          params.page = page;
        }

        if (search !== undefined && search !== "") {
          params.search = search;
        }

        return { url, params };
      },

      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: tags.PAYMENT_CONTROL_TAG,
                id,
              })),
              { type: tags.PAYMENT_CONTROL_TAG, id: "LIST" },
            ]
          : [{ type: tags.PAYMENT_CONTROL_TAG, id: "LIST" }],
    }),

    updateApplicantsPaymentStatus: builder.mutation({
      query: ({ applicantId, status }) => ({
        url: `/${endpoint}/${applicantId}`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.PAYMENT_CONTROL_TAG, id: arg._id },
      ],
    }),

    uploadApplicantsProofOfPayment: builder.mutation({
      query: ({ applicantId, proofOfPayment }) => ({
        url: `/${endpoint}/${applicantId}`,
        method: "PUT",
        body: { proofOfPayment },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.PAYMENT_CONTROL_TAG, id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetApplicantPaymentsQuery,
  useUpdateApplicantsPaymentStatusMutation,
  useUploadApplicantsProofOfPaymentMutation,
} = applicants;
