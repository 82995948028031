import MainLayout from "Component/Layout/MainLayout";
import Select from "Component/Forms/Select";
import { FcFilledFilter } from "react-icons/fc";
import DashboardCount from "Component/DashboardCount";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";

const Dashboard = () => {
  return (
    <MainLayout>
      <div className=" ">
        <BodyHeaderLayout title={"Admission dashboard"}>
          {null && (
            <div className="flex sm:w-[100%] gap-2 items-center">
              <Select
                mainContainerStyle="sm:w-full"
                className="w-[200px] sm:w-full h-[41px]"
                optionClass="z-50"
                data={[
                  { value: "2023/2024 session", label: "2023/2024 session" },
                ]}
                value={{ value: "null", label: "2023/2024 session" }}
                // onChangeOption={(x)=>props.setFieldValue('state',x?.value)}
              />
              <FcFilledFilter className="text-[33px]" />
            </div>
          )}
        </BodyHeaderLayout>

        <div className="flex flex-col md:flex-row gap-6 flex-wrap ">
          <DashboardCount
            title="Total Applicants"
            count={0}
            bgColor="bg-[blue]"
            textColor="text-blue-500"
          />

          <DashboardCount
            title="Approved Applicants"
            count={0}
            bgColor="bg-[green]"
            textColor="text-green-500"
          />

          <DashboardCount
            title="Pending Applicants"
            count={0}
            bgColor="bg-[orange]"
            textColor="text-orange-500"
          />

          <DashboardCount
            title="Rejected Applicants"
            count={0}
            bgColor="bg-[red]"
            textColor="text-red-500"
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Dashboard;
