import Logo from "../Logo";
import Toggle from "./Toggle";
import { useParams, useNavigate } from "react-router-dom";
import { CgMenuLeft } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { setToggleMobileNav } from "../../Store/AppSlice";
import { MdLightMode } from "react-icons/md";
import { IoCalendarClearOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { CiUser } from "react-icons/ci";
import { PopOverSimple, PopOverList } from "../PopOver";
import { generalSettingsRoute } from "Utils/navigationRoutes";

const firstLetter = (text) => {
  return text?.substring(0, 1).toUpperCase();
};

const Header = ({ schoolLogo, session, userInfo }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showTerm = (term) => {
    switch (term) {
      case 1:
        return "First Term";
      case 2:
        return "Second Term";
      case 3:
        return "Third Term";
      default:
        return "Not Valid";
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("school_token");
    localStorage.removeItem("school_decode_token");

    navigate(`/${params.shortName}`, { replace: true });
  };

  return (
    <div className="h-16 sm:h-12 fixed z-20 bg-white w-full shadow-select flex justify-between items-center px-3">
      <div className="w-[33%] sm:hidden">
        <Logo image={schoolLogo} />
      </div>

      <div className="lg:hidden">
        <CgMenuLeft
          onClick={() => {
            dispatch(setToggleMobileNav(true));
          }}
          size={25}
        />
      </div>
      <div className="flex justify-center w-[33%] sm:w-auto">
        <Toggle />
      </div>

      <div className="flex justify-end items-center gap-5  w-[33%] sm:w-auto">
        <div className="flex items-center gap-2 sm:hidden">
          <IoCalendarClearOutline size={12} />
          <span className="font-light text-[12px]">
            {showTerm(session?.term)} - {session?.session}
          </span>
        </div>

        <div className="flex justify-between">
          <PopOverSimple
            className="mt-[55px] sm:mt-[45px]"
            header={
              <div className="h-10 sm:h-[33px] sm:w-[33px] w-10 sm:text-[14px] cursor-pointer bg-gray-300 rounded-full flex items-center justify-center">
                {firstLetter(userInfo?.firstName)}
                {firstLetter(userInfo?.lastName)}
              </div>
            }
            content={
              <div>
                <div className="bg-slate-100 pb-3">
                  <div className="pt-3 text-center">
                    <span className="px-2 py-5 ">
                      {userInfo?.firstName}
                      {userInfo?.lastName}
                    </span>
                  </div>
                  <div className="text-center">
                    <span className="text-[12px] text-slate-700 px-2 text-center pb-2">
                      {userInfo?.email}
                    </span>
                  </div>
                </div>

                <PopOverList
                  items={[
                    {
                      name: "My Profile",
                      icon: <CiUser size={23} />,
                      linkToItem: `/${params.shortName}/${generalSettingsRoute.profile}`,
                    },
                    {
                      name: "Notifications",
                      icon: <IoIosNotificationsOutline size={23} />,
                    },
                    {
                      name: "Sign Out",
                      icon: <LiaSignOutAltSolid size={23} />,
                      onClick: handleLogout,
                    },
                  ]}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
