import { useState, useEffect, useCallback } from "react";
import { CiLink } from "react-icons/ci";
import { CircularProgress } from "@mui/material";

import Input, { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import Select from "Component/Forms/Select";
import useCountry from "./hooks/useCountry";
import { useCheckShortNameMutation } from "Network/Auth/auth";
import { replaceWhiteSpaces } from "Utils";

const FirstScreen = ({ formikProps }) => {
  const [urlShortName, setUrlShortName] = useState("");

  const [checkShortName, checkingShortNameState] = useCheckShortNameMutation();

  const BASE_URL = process.env.REACT_APP_WEB_BASE_URL;

  const {
    countryValue,
    stateValue,
    localGovernmentValue,
    setCountryValue,
    setLocalGovernmentValue,
    setStateValue,
    stateList,
    localGovernmentList,
    countryList,
  } = useCountry(formikProps);

  const handleInputChange = useCallback(
    (text) => {
      if (text.length !== 0) {
        checkShortName(text);
        setUrlShortName(text);
      } else {
        setUrlShortName("");
      }
    },
    [formikProps.values.shortName],
  );

  useEffect(() => {
    handleInputChange(formikProps.values.shortName);
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className=" text-primary flex flex-col justify-between">
        <h2 className="font-bold text-[16px]">School information</h2>
        <p className="!text-[12px] !text-slate-500">
          Provide necessary information about your school
        </p>
      </div>

      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-5 w-full sm:flex-col">
          <Input
            withAsterik
            withTitle
            labelStyle="font-bold"
            title="School Name"
            placeholder="E.g Best Choice Academy"
            name="schoolName"
          />

          <InputNoFormik
            withAsterik
            withTitle
            title="School short name "
            labelStyle="font-semibold"
            placeholder="e.g my-school"
            name="shortName"
            value={formikProps.values.shortName}
            showError
            error={formikProps.errors?.shortName}
            touched={formikProps?.touched?.shortName}
            onChange={(e) => {
              formikProps.setFieldValue("shortName", e.target.value);
              handleInputChange(e.target.value);
            }}
          />
        </div>

        <div className="flex gap-5 sm:flex-col">
          <Input
            withAsterik
            withTitle
            title="Address"
            placeholder="Enter address"
            name="address"
            labelStyle="font-bold"
          />
          <div className="flex flex-col  w-[100%]">
            <p className="text-[14px] font-bold">Country</p>
            <Select
              mainContainerStyle={`w-full`}
              className={`${formikProps.errors?.country && "!border-[red] "}`}
              data={countryList}
              value={countryValue}
              onChangeOption={(x) => {
                formikProps.setFieldValue("country", x?.value);
                setCountryValue(x);
              }}
            />
          </div>
        </div>
        <div className="flex gap-5 sm:flex-col w-[100%]">
          <div className="flex flex-col w-full">
            <p className="text-[14px] font-semibold">State</p>

            <Select
              className={`${formikProps.errors?.state && "!border-[red]"}`}
              data={
                countryValue?.value?.length
                  ? stateList
                  : [
                      {
                        label: "Choose a country first",
                        value: null,
                      },
                    ]
              }
              value={stateValue}
              mainContainerStyle="w-full"
              optionClass="w-[300px]"
              onChangeOption={(x) => {
                if (!x?.value) return;
                formikProps.setFieldValue("state", x?.value);
                formikProps.setFieldValue("localGovernment", "");
                setLocalGovernmentValue({
                  value: "",
                  label: "Choose a local government",
                });
                setStateValue(x);
              }}
            />
          </div>

          <div className="flex flex-col w-[100%]">
            <p className="text-[14px] font-semibold">Local Government</p>

            <Select
              mainContainerStyle={`w-full`}
              className={`${
                formikProps.errors?.localGovernment && "!border-[red] "
              }`}
              data={
                stateValue?.value?.length
                  ? localGovernmentList
                  : [
                      {
                        label: "Choose a state first",
                        value: null,
                      },
                    ]
              }
              value={localGovernmentValue}
              onChangeOption={(x) => {
                if (!x?.value) return;
                formikProps.setFieldValue("localGovernment", x?.value);
                setLocalGovernmentValue(x);
              }}
            />
          </div>
        </div>
        {!checkingShortNameState.isLoading ? (
          urlShortName !== "" && (
            <div>
              <div className="flex border-[#ddd] border-1 bg-slate-200">
                <div className="bg-primary p-1 sm:flex sm:items-center">
                  <CiLink size={33} color="white" />
                </div>
                <div className="p-2 text-blue-950 ">
                  {checkingShortNameState.isSuccess ? (
                    <span className="text-[12px] sm:text-[10px] font-bold">
                      {BASE_URL}/
                      {replaceWhiteSpaces(urlShortName.toLocaleLowerCase())}
                    </span>
                  ) : (
                    <span className="text-[12px] text-red-600">
                      {checkingShortNameState?.error?.data?.message}
                    </span>
                  )}
                </div>
              </div>
              {checkingShortNameState.isSuccess && (
                <p className="text-[12px] sm:text-center text-slate-500 mt-[3px]">
                  The link above will be the main url link to access your portal
                </p>
              )}
            </div>
          )
        ) : (
          <CircularProgress size={16} />
        )}
      </div>

      <Button
        handleSubmit={formikProps.handleSubmit}
        title="Next"
        className="w-20 sm:w-full h-9 self-end"
      />
    </div>
  );
};

export default FirstScreen;
