import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { decodeToken } from "react-jwt";

const useReturnSchool = () => {
  const params = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem("school_token");
  const decodedToken = decodeToken(token);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    if (params.shortName !== decodedToken?.schoolShortName) {
      navigate("/");
    }
  }, [params.shortName]);

  return { decodedToken };
};

export default useReturnSchool;
