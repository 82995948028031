import dayjs from "dayjs";

import {
  TableWrapper,
  THead,
  TRow,
  TableLoader,
  TableNoData,
} from "Component/Table";
import Actions from "../Manage/Actions";
import StatusIdentifier from "Component/StatusIdentifier";
import Avatar from "Component/Avatar";

const ApplicantDeskTopView = ({ handleView = () => {}, applicantsState }) => {
  const { data: applicants, isFetching } = applicantsState;

  return (
    <TableWrapper>
      <table className="w-full">
        <THead className="w-full">
          <td className="flex justify-center py-5 min-w-[2%]">S/N</td>
          <td className="w-[22%] px-2">Applicant</td>
          <td className="w-[10%] px-2">Section</td>
          <td className="w-[10%] px-2">Class</td>
          <td className="w-[12%] px-2">Payment Type</td>
          <td className="w-[14%] px-2">Payment Status</td>
          <td className="w-[12%] px-2">Date</td>
          <td className="w-[14%] px-2">Admission Status</td>
          <td className="w-[2%] pr-2 ">Actions</td>
        </THead>
        {!isFetching ? (
          <tbody>
            {applicants?.data?.length !== 0 ? (
              applicants?.data?.map((data, index) => (
                <TRow key={index}>
                  <td className="px-6 py-5">{index + 1}</td>
                  <td className="flex gap-2 items-center py-2 px-2">
                    <Avatar image={data?.applicant?.photo} />
                    <div className="flex flex-col">
                      <span className="text-[12] mt-[3px]">
                        {`${data?.applicant?.firstName} ${data?.applicant?.middleName || ""} ${data?.applicant?.lastName}`}
                      </span>
                      <span className="text-[8] font-extralight text-primary  rounded w-fit p-[2px]">
                        {data?.applicant?.applicantNumber || "Applicant ID"}
                      </span>
                    </div>
                  </td>
                  <td className="px-2">{data?.section?.name}</td>
                  <td className="px-2">{data?.class?.name}</td>
                  <td className="px-2">{data?.mode}</td>
                  <td className="px-2">
                    {" "}
                    <StatusIdentifier status={data?.status} />
                  </td>
                  <td className="px-2">
                    {dayjs(data?.createdAt).format("DD MMM YYYY")}
                  </td>
                  <td className="px-2">
                    <StatusIdentifier
                      status={data?.applicant?.admissionStatus}
                    />
                  </td>
                  <td>
                    <Actions data={data} />
                  </td>
                </TRow>
              ))
            ) : (
              <TableNoData colSpan={9} />
            )}
          </tbody>
        ) : (
          <TableLoader colSpan={9} />
        )}
      </table>
    </TableWrapper>
  );
};

export default ApplicantDeskTopView;
