/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";

import { useEditBankMutation } from "Network/Configurations/bank";

import { toast } from "react-toastify";

const useEditBank = () => {
  const [editBank, editBankMutationState] = useEditBankMutation();
  useEffect(() => {
    if (editBankMutationState.isError) toast.error("Something went wrong");
    if (editBankMutationState.isSuccess)
      toast.success("Payment Account edited");
  }, [editBankMutationState.isSuccess]);

  return { editBank, editBankMutationState };
};

export default useEditBank;
