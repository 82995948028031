import TestimonialCard from "./TestimonialCard";
import { testimonials } from "Screens/Website/utils";

const Testimonials = () => {
  return (
    <div className="flex flex-col gap-2 items-center pt-10 sm:pt-3">
      <p className="text-primary font-semibold text-[50px] sm:text-[30px] ">
        Testimonials{" "}
      </p>
      <p className="text-[30px] sm:text-[18px] text-primary w-[60%] sm:w-[90%] mx-auto text-center">
        See What our satisfied users have to say about Softschool
      </p>

      <div className="mt-10 sm:mt-5 w-[90%] mx-auto flex sm:flex-col sm:gap-6 sm:items-center justify-between">
        {testimonials.map((test, index) => (
          <TestimonialCard key={index} {...test} />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
