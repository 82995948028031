/* eslint-disable no-use-before-define */
import { useState } from "react";
import MainLayout from "Component/Layout/MainLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";

import { useGetBank, useCreateBank, useEditBank } from "./hooks/bank";

const initialValues = {
  bankName: "",
  accountName: "",
  accountNumber: "",
};

const validationSchema = Yup.object().shape({
  bankName: Yup.string().required("Bank name is required"),
  accountName: Yup.string().required("Account name is required"),
  accountNumber: Yup.string()
    .matches(/^\d{10}$/, "Account number must be exactly 10 digits")
    .required("Account number is required"),
});

const BankControl = () => {
  const { createBank, bankMutationState } = useCreateBank();
  const { editBank, editBankMutationState } = useEditBank();

  const handleSubmit = (values) => {
    if (bankQuery.isSuccess) {
      editBank({ data: values, id: bankQuery?.data?._id });
    } else {
      createBank({ ...values, paymentMode: "online" });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const setForm = () => {
    formik.setFieldValue("bankName", bankQuery?.data?.bankName);
    formik.setFieldValue("accountName", bankQuery?.data?.accountName);
    formik.setFieldValue("accountNumber", bankQuery?.data?.accountNumber);
    setDisabled(true);
  };
  const { bankQuery } = useGetBank(setForm);

  const [disabled, setDisabled] = useState(false);

  return (
    <MainLayout isLoading={bankQuery.isLoading}>
      <div>
        <div className="mt-[20px] font-bold">
          <span className="text-[14px]">Payment Creation</span>
        </div>
        <div>
          <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
            <div className="flex gap-4 w-full sm:flex-col">
              <InputNoFormik
                withTitle
                title="Bank Name"
                placeholder="Enter Bank name"
                name="bankName"
                showError
                value={formik.values.bankName}
                handleChange={formik.handleChange}
                error={formik.errors?.bankName}
                touched={formik.touched?.bankName}
                disabled={disabled}
              />
            </div>
            <div className="flex gap-4 w-full sm:flex-col">
              <InputNoFormik
                withTitle
                title="Account Number"
                placeholder="Enter 10 digit account number"
                name="accountNumber"
                showError
                value={formik.values.accountNumber}
                handleChange={formik.handleChange}
                error={formik.errors?.accountNumber}
                touched={formik.touched?.accountNumber}
                disabled={disabled}
              />
              <InputNoFormik
                withTitle
                title="Account Name"
                placeholder="Enter account name"
                name="accountName"
                showError
                value={formik.values.accountName}
                handleChange={formik.handleChange}
                error={formik.errors?.accountName}
                touched={formik.touched?.accountName}
                disabled={disabled}
              />
            </div>
            {!bankQuery.isSuccess ? (
              <Button
                handleSubmit={formik.handleSubmit}
                title="Create payment"
                className="lg:w-[20%] md:w-[50%] sm:w-[50%]"
                isLoading={bankMutationState.isLoading}
              />
            ) : (
              <div className="flex gap-3">
                {disabled ? (
                  <Button
                    handleSubmit={() => setDisabled(false)}
                    title="Edit"
                    className="lg:w-[20%] md:w-[50%] sm:w-[50%]"
                    isLoading={bankMutationState.isLoading}
                  />
                ) : (
                  <Button
                    handleSubmit={setForm}
                    title="Cancel"
                    className="lg:w-[20%] md:w-[50%] sm:w-[50%] bg-white text-[black] border-1 border-primary"
                    isLoading={bankMutationState.isLoading}
                  />
                )}

                {!disabled && (
                  <Button
                    handleSubmit={formik.handleSubmit}
                    title="Save"
                    className="lg:w-[20%] md:w-[50%] sm:w-[50%]"
                    isLoading={editBankMutationState.isLoading}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default BankControl;
