import { useState } from "react";
import {
  useGetSectionsQuery,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useEditSectionMutation,
} from "../../../../../Network/classes/section";
import { toast } from "react-toastify";

const useSection = () => {
  const sections = useGetSectionsQuery();
  const [creatSection, createSectionState] = useCreateSectionMutation();
  const [deleteSection, deleteSectionState] = useDeleteSectionMutation();
  const [editSection, editSectionState] = useEditSectionMutation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = (values, actions) => {
    creatSection(values)
      .unwrap()
      .then(() => actions.resetForm())
      .catch(() => toast.error("Something went wrong"));
  };

  const handleDelete = () => {
    deleteSection({ id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Section deleted successfully");
        setConfirmDelete(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  const handleUpdate = (value) => {
    editSection({ data: { name: value }, id: selectedItem?._id })
      .unwrap()
      .then(() => {
        toast.success("Section Edited successfully");
        setOpenUpdateModal(false);
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  return {
    sections,
    createSectionState,
    deleteSectionState,
    editSectionState,
    confirmDelete,
    setConfirmDelete,
    openUpdateModal,
    setOpenUpdateModal,
    selectedItem,
    setSelectedItem,
    handleSubmit,
    handleDelete,
    handleUpdate,
  };
};

export default useSection;
