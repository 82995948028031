import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router";

import Button from "./Forms/Button";

const BackButton = ({ navigateTo }) => {
  const navigate = useNavigate();
  return (
    <Button
      Icon={<MdKeyboardArrowLeft size={20} className="text-black" />}
      variant="outline"
      className="w-[65px] py-1 text-[12px] gap-1"
      title="Back"
      handleSubmit={() => navigate(navigateTo)}
    />
  );
};

export default BackButton;
