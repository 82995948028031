import CustomModal from "Component/Forms/CustomModal";
import { Formik } from "formik";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import * as Yup from "yup";
import { useCreateSectionMutation } from "Network/classes/section";
import { toast } from "react-toastify";

const CreateSection = ({ openCreateSection, setOpenCreateSection }) => {
  const [createSection, sectionState] = useCreateSectionMutation();
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Section name is required"),
  });

  const handleSubmit = (values, action) => {
    createSection(values)
      .unwrap()
      .then((res) => {
        toast.success("Section created");
        setOpenCreateSection(false);
        action.resetForm();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <CustomModal
      open={openCreateSection}
      handleClose={() => !sectionState.isLoading && setOpenCreateSection(false)}
      modalClassName="min-h-[100px] sm:h-[165px] sm:top-32 w-[35%]"
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="flex flex-col h-full mt-5 gap-4 w-full ">
              <Input
                withTitle
                title="Section Name"
                placeholder="Example Nursery, Primary, Junior or Senior"
                name="name"
                className="text-primary font-semibold"
              />

              <Button
                isLoading={sectionState.isLoading}
                handleSubmit={props.handleSubmit}
                title="Add Section"
                className="w-fit text-[12px] py-2 px-4"
              />
            </div>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default CreateSection;
