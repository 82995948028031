import { toast } from "react-toastify";

import api from "../apiSlice";
import { tags } from "../../Utils/constants";
import { getSchoolId } from "../utils";

const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    getSession: builder.query({
      query: () => ({
        url: `/user/session/get-session-controls/${getSchoolId()}`,
      }),
      providesTags: [tags.SESSION_CONTROL_TAG],
    }),

    setSession: builder.mutation({
      query: (data) => ({
        url: "/user/session/set-current-session",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [tags.SESSION_CONTROL_TAG],
      async onQueryStarted(_args, { queryFulfilled: qf }) {
        qf.then(() => {
          toast.success("New Session");
        }).catch((err) => {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error("Something went wrong");
          }
        });
      },
    }),
  }),
});

export const { useGetSessionQuery, useSetSessionMutation } = auth;
