import CustomModal from "Component/Forms/CustomModal";
import { Formik } from "formik";
import Button from "Component/Forms/Button";
import Input from "Component/Forms/Input";
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from "./validators";
import { useChangePassword } from "./hooks";

const ChangePassword = ({ openChangePassword, setOpenChangepassword }) => {
  const [handleChangePassword, changePasswordState] = useChangePassword(
    setOpenChangepassword,
  );

  const handleSubmit = (values) => {
    handleChangePassword({
      oldpassword: values.oldPassword,
      newpassword: values.newPassword,
    });
  };
  return (
    <CustomModal
      open={openChangePassword}
      handleClose={() => setOpenChangepassword(false)}
      modalClassName="w-[35%]"
    >
      <div>
        <Formik
          initialValues={changePasswordInitialValues}
          validationSchema={changePasswordValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="min-h-[30vh] w-full bg-white  px-8 py-5 flex flex-col gap-5 mt-3">
              <div className="flex flex-col gap-4 w-full">
                <span className="text-[23px] font-bold">Change Password</span>
                <Input
                  withTitle
                  withAsterik
                  labelStyle="font-semibold"
                  title="Old Password"
                  placeholder="Enter your old Password"
                  name="oldPassword"
                  type="password"
                  className="text-primary font-semibold"
                />
                <Input
                  withTitle
                  withAsterik
                  labelStyle="font-semibold"
                  title="New Password"
                  placeholder="Enter your New Password"
                  name="newPassword"
                  type="password"
                  className="text-primary font-semibold"
                />
                <Input
                  withTitle
                  withAsterik
                  labelStyle="font-semibold"
                  title="Confirm New Password"
                  placeholder="Confirm your New Password"
                  name="confirmPassword"
                  type="password"
                  className="text-primary font-semibold"
                />

                <Button
                  isLoading={changePasswordState.isLoading}
                  handleSubmit={props.handleSubmit}
                  title="Change Password"
                  className="text-[14px] py-2"
                />
              </div>
            </div>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default ChangePassword;
