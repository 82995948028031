const validateFileURL = async (url) => {
  try {
    const response = await fetch(url, { method: "HEAD" });
    if (!response.ok) {
      return false;
    }
    const contentType = response.headers.get("content-type");
    if (contentType) {
      return contentType.includes("image") || contentType.includes("pdf");
    }
    return false;
  } catch (error) {
    console.error("Error validating URL:", error);
    return false;
  }
};

export default validateFileURL;
