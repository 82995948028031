import { create } from "zustand";

const usePaymentFilters = create((set) => ({
  page: 1,
  search: "",
  setPage: (page) => set({ page }),
  setSearch: (search) => set({ search }),

  openView: false,
  openUpload: false,

  setOpenView: (openView) => set({ openView }),
  setOpenUpload: (openUpload) => set({ openUpload }),

  applicants: null,
  setApplicants: (applicants) => set({ applicants }),

  handleSearch: (text) => {
    set((state) => {
      if (text.length >= 3 || text === "") {
        return { search: text };
      }
      return state;
    });
  },
}));

export default usePaymentFilters;
