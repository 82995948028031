import {
  AccordionRoot,
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
} from "Component/Accordion";
import { faqs } from "Screens/Website/utils";

const Faq = () => {
  return (
    <div className="mt-24 sm:mt-10 flex flex-col gap-5 items-center py-16 sm:py-5 bg-[#88aed1]">
      <p className="font-semibold text-primary text-[50px] sm:text-[18px] text-center w-[50%] sm:w-[70%] mx-auto">
        Frequent Asked Question FAQs
      </p>

      <AccordionRoot defaultValue={1} className={"w-[60%] sm:w-[80%] mx-auto"}>
        {faqs.map((faq, index) => (
          <AccordionItem value={index.toString()}>
            <AccordionTrigger
              className="pt-3 sm:p-0 sm:pb-1 sm:min-h-[10px] bg-transparent sm:justify-end border-0 border-b-[3px] sm:border-b-[1px] border-primary sm:text-start"
              accordionTriggerTitle={
                <p className="text-[24px] sm:text-[12px] text-primary font-medium">
                  {faq.title}
                </p>
              }
            />
            <AccordionContent className="sm:text-xs">
              {faq.text}
            </AccordionContent>
          </AccordionItem>
        ))}
      </AccordionRoot>
    </div>
  );
};

export default Faq;
