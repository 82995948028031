import { useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useGetDocumentsQuery } from "Network/Configurations/supportingDocument";
import { useGetEntryRequirementsQuery } from "Network/Configurations/admissionEntryRequirement";
import {
  useGetSectionsQuery,
  useGetSectionClassesQuery,
} from "Network/classes/section";
import { paymentOptions } from "Utils/constants";
import { useEditApplicationMutation } from "Network/applications";
import { transformSelectOption, transformClassOption } from "Utils/transform";
import { useGetAttachmentsQuery } from "Network/Configurations/attachment";

const useEditApplication = (data, setOpen) => {
  const [editApplication, editApplicationsState] = useEditApplicationMutation();
  const documents = useGetDocumentsQuery();
  const sections = useGetSectionsQuery();
  const entryRequirements = useGetEntryRequirementsQuery();
  const attachments = useGetAttachmentsQuery();

  const [selectedSection, setSelectedSection] = useState({
    label: data?.section?.name,
    value: data?.section?._id,
  });

  const classes = useGetSectionClassesQuery(selectedSection?.value, {
    skip: !selectedSection?.value || !data?.section?._id,
  });

  const [selectedPaymentMode, setSelectedPaymentMode] = useState({
    ...paymentOptions.filter((x) => x.value === data.paymentMode)[0],
  });

  const [selectedDocument, setSelectedDocument] = useState(
    transformSelectOption(data?.supportingDocuments),
  );

  const [selectedRequirement, setSelectedRequirement] = useState(
    transformSelectOption(data?.entryRequirements, "requirementText"),
  );

  const [selectedClasses, setSelectedClasses] = useState(
    transformClassOption(data?.classes),
  );

  const [selectedAttachment, setSelectedAttachment] = useState(
    transformSelectOption(data?.attachments),
  );

  const initialValues = {
    description: data?.description || "",
    sectionId: data?.section?._id || "",
    fee: data?.fee || 0,
    paymentMode: data?.paymentMode,
    supportingDocuments: selectedDocument?.map((x) => x.value) ?? [],
    entryRequirements: selectedRequirement?.map((x) => x.value) ?? [],
    classes: selectedClasses?.map((x) => x.value) ?? [],
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Name is required"),
    sectionId: Yup.string().required("Section is required"),
    classes: Yup.array().required("Class is required"),
    supportingDocuments: Yup.array().required(
      "Supporting Documents is required",
    ),
    entryRequirements: Yup.array().required("Entry Requirements is required"),
    paymentMode: Yup.string().required("Payment Mode is required"),
  });
  const handleSubmit = (values) => {
    editApplication({ data: values, id: data._id })
      .unwrap()
      .then((_) => {
        toast.success("Application edited");
        setOpen(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return {
    documents,
    classes,
    sections,
    selectedClasses,
    setSelectedClasses,
    selectedDocument,
    setSelectedDocument,
    selectedPaymentMode,
    setSelectedPaymentMode,
    selectedSection,
    setSelectedSection,
    formik,
    editApplicationsState,
    paymentOptions,
    selectedRequirement,
    setSelectedRequirement,
    entryRequirements,

    attachments,
    selectedAttachment,
    setSelectedAttachment,
  };
};

export default useEditApplication;
