import { create } from "zustand";

export const AuthModalPages = {
  LOGIN: { id: 1, title: "Login" },
  SIGNUP: { id: 2, title: "Create Account" },
  FORGET_PASSWORD: { id: 3, title: "Forget Password" },
  RESET_PASSWORD: { id: 4, title: "Reset Password" },
};

const useAuthStore = create((set) => ({
  open: false,
  page: AuthModalPages.LOGIN,
  loading: false,

  setOpen: (open) => set({ open }),
  setPage: (page) => set({ page }),
  setLoading: (loading) => set({ loading }),
}));

export default useAuthStore;
