import { useState, useCallback } from "react";
import { toast } from "react-toastify";

import useApplicantStore from "./useApplicantStore";
import { genderList } from "Utils/constants";
import { useGetApplicationsQuery } from "Network/applications";
import { useGetClassQuery } from "Network/classes/class";
import { applicantValidationSchema } from "./useCreateApplicants";
import { useEditApplicantsMutation } from "Network/applicants";
import { transformClassOption } from "Utils/transform";

const useEditApplicant = (setOpen) => {
  const [editApplicant, editApplicantState] = useEditApplicantsMutation();
  const { applicants } = useApplicantStore();

  const applications = useGetApplicationsQuery({
    page: undefined,
    published: true,
  });

  const [selectedGender, setSelectedGender] = useState(
    genderList.filter((gender) => gender.value === applicants?.gender)[0] || [],
  );

  const [selectedApplication, setSelectedApplication] = useState({
    label: `${applicants?.application?.description}: ${applicants?.application?.session}`,
    value: applicants?.application?._id,
  });

  const [selectedClass, setSelectedClass] = useState(
    transformClassOption([
      { ...applicants?.class, category: applicants?.category },
    ])[0],
  );

  const singleApplication = useGetApplicationsQuery(
    { id: selectedApplication?.value },
    {
      skip: !selectedApplication,
      selectFromResult: ({ data }) => ({
        application: data?.data?.find(
          (application) => application._id === selectedApplication?.value,
        ),
      }),
    },
  );

  const getCategoryId = useCallback(() => {
    return (
      singleApplication?.application?.classes?.find(
        (cl) => cl?._id === selectedClass?.value,
      )?.category?._id || undefined
    );
  }, [selectedClass?.value]);

  const singleClass = useGetClassQuery(selectedClass?.value, {
    skip: !selectedClass,
  });

  const initialValues = {
    firstName: applicants?.firstName,
    middleName: applicants?.middleName,
    lastName: applicants?.lastName,
    dob: applicants.dob,
    gender: applicants?.gender,
    address: applicants?.address,
    classId: applicants?.class?._id,
    sectionId: applicants?.section?._id,
    categoryId: applicants?.category?._id,
    applicationId: applicants?.application?._id,
    medicalInfo: applicants?.medicalInfo,
    photo: applicants?.photo,
    parentOrGuardianInfo: {
      fullName: applicants?.parentOrGuardianInfo?.fullName,
      relationShip: applicants?.parentOrGuardianInfo?.relationShip,
      phone: applicants?.parentOrGuardianInfo?.phone,
      email: applicants?.parentOrGuardianInfo?.email,
    },
  };

  const handleSubmit = (values) => {
    editApplicant({
      data: {
        ...values,
        categoryId: getCategoryId(),
        address: values?.address || undefined,
        middleName: values.middleName || undefined,
        medicalInfo: values.medicalInfo || undefined,
      },
      id: applicants?._id,
    })
      .unwrap()
      .then((_) => {
        setOpen(false);
        toast.success("Application Edited");
      })
      .catch((err) => {
        setOpen(false);
        if (err.status === 400) {
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    applicants,
    applications,
    selectedGender,
    selectedApplication,
    selectedClass,
    singleApplication,
    singleClass,
    initialValues,
    handleSubmit,
    validationShcema: applicantValidationSchema,
    setSelectedGender,
    setSelectedApplication,
    setSelectedClass,
    editApplicantState,
  };
};

export default useEditApplicant;
