import api from "../apiSlice";

const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: "/auth/register-school",
        method: "POST",
        body: data,
      }),
    }),

    registerUser: builder.mutation({
      query: (data) => ({
        url: "/users",
        method: "POST",
        body: data,
      }),
    }),

    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data,
      }),
    }),

    checkShortName: builder.mutation({
      query: (shortName) => ({
        url: `/auth/check-shortname`,
        method: "POST",
        body: { shortName },
      }),
    }),

    verifyEmail: builder.query({
      query: (hash) => ({
        url: `/auth/verify-email/${hash}`,
      }),
    }),

    resendVerifyEmail: builder.mutation({
      query: (data) => ({
        url: "/auth/resend-verify-email",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useRegisterUserMutation,
  useLoginMutation,
  useCheckShortNameMutation,
  useVerifyEmailQuery,
  useResendVerifyEmailMutation,
} = auth;
