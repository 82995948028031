import { RxDashboard } from "react-icons/rx";
import NavItem from "./NavItem";

const AcademicNav = ({ toggle }) => {
  return (
    <div
      className="flex flex-col gap-5 overflow-y-auto h-full pb-3"
      style={{ scrollbarWidth: "thin" }}
    >
      <NavItem
        Icon={RxDashboard}
        text={"Dashboard"}
        active={"/dashboard"}
        toggle={toggle}
      />
    </div>
  );
};

export default AcademicNav;
