import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";

import cn from "Utils/cn";
import validateFileURL from "Network/Auth/validateFileURL";

const DisplayFile = ({ fileUrl, className }) => {
  const [isValid, setIsValid] = useState(null);

  const isImage = (url) => {
    const regex = /\.(jpg|jpeg|png|gif)$/i;
    return regex.test(url);
  };
  useEffect(() => {
    const checkUrl = async () => {
      const valid = await validateFileURL(fileUrl);
      setIsValid(valid);
    };

    checkUrl();
  }, [fileUrl]);

  return (
    <div className={cn(className)}>
      {isValid !== null ? (
        isValid ? (
          <div className="w-full h-full">
            {isImage(fileUrl) ? (
              <div className="flex justify-center items-center overflow-hidden max-w-full h-auto overflow-y-auto">
                <img
                  src={fileUrl}
                  alt=""
                  className="w-full h-auto object-cover"
                />
              </div>
            ) : (
              <object
                data={fileUrl}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p>
                  Alternative text - include a link{" "}
                  <a href={fileUrl}>to the PDF!</a>
                </p>
              </object>
            )}
          </div>
        ) : (
          <p className="text-red-500 w-full h-full flex justify-center items-center">
            File not found
          </p>
        )
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default DisplayFile;
