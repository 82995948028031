import { Route } from "react-router-dom";

import { navigationRoutes, schoolWebpagesRoute } from "Utils/navigationRoutes";
import AdmissionPage from "Screens/SchoolWebpages/AdmissionPage";
import Admissions from "Screens/SchoolWebpages/Admissions";
import SchoolWebsite from "Screens/SchoolWebpages/Website";

const SchoolWebpagesRoutes = [
  <Route
    key={"schoolWebsite"}
    path={`/:shortName`}
    element={<SchoolWebsite />}
  />,
  <Route
    key={"admissions"}
    path={schoolWebpagesRoute.admissions}
    element={<Admissions />}
  />,
  <Route
    key={"admission/:shortName"}
    path={`${schoolWebpagesRoute.admissions}${navigationRoutes.SHORTNAME}`}
    element={<AdmissionPage />}
  />,
];

export default SchoolWebpagesRoutes;
