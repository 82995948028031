import { useParams } from "react-router";

import ComingSoon from "Component/ComingSoon";
import PreLayout from "Component/Layout/PreLayout";
import capitalizeFirstLetter from "Utils/capitalizeFirstLetter";
import { navigationRoutes } from "Utils/navigationRoutes";

const SchoolWebsite = () => {
  const { shortName } = useParams();
  return (
    <PreLayout>
      <ComingSoon
        title={`${capitalizeFirstLetter(shortName)} dashboard`}
        navigateTo={`/${shortName}${navigationRoutes.LOGIN}`}
        buttonTitle="Go to Login"
      />
    </PreLayout>
  );
};

export default SchoolWebsite;
