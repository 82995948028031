import cn from "Utils/cn";
import { cva } from "class-variance-authority";

const featuresCardVariants = cva(
  "w-[449px] sm:w-[197px] h-[131px] sm:h-auto sm:py-2 bg-primary text-white flex flex-col gap-2 sm:gap-0 justify-center items-center px-2",
  {
    variants: {
      variant: {
        default: "rounded-sm",
        left: "rounded-l-2xl border-r-[6px] sm:border-r-[2px] border-border-red",
        right:
          "rounded-r-2xl border-l-[6px] sm:border-l-[2px] border-border-red",
      },
    },

    defaultVariants: {
      variant: "default",
    },
  },
);

const FeaturesCard = ({ title, text, className, variant }) => {
  return (
    <div className={cn(featuresCardVariants({ variant, className }))}>
      <span className="text-lg sm:text-[10px] font-semibold p-0">{title}</span>
      <span className="text-[16px] sm:text-[8px] text-center p-0">{text}</span>
    </div>
  );
};

export default FeaturesCard;
