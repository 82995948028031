import { useState } from "react";

import usePublishApplication from "./usePublishApplication";
import useDeleteApplication from "./useDeleteApplication";
import { useGetApplicationsQuery } from "Network/applications";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";

const useApplication = () => {
  const { handlePublish, publishApplicationsState } = usePublishApplication();
  const { handleDelete, deleteApplicationsState } = useDeleteApplication();

  const { page, setPage, search, handleSearch } = usePaginationAndSearch();
  const applicationsState = useGetApplicationsQuery({ page, search });
  const [openEdit, setOpenEdit] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);

  const [selectedItems, setSelectedItems] = useState();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [openView, setOpenView] = useState(false);

  const handleOpenView = (data) => {
    setOpenView(true);
    setSelectedItems(data);
  };
  return {
    handleDelete,
    deleteApplicationsState,
    handlePublish,
    publishApplicationsState,
    applicationsState,
    openEdit,
    setOpenEdit,
    openConfirmBox,
    setOpenConfirmBox,
    selectedItems,
    setSelectedItems,
    openConfirmDelete,
    setOpenConfirmDelete,
    openView,
    setOpenView,
    handleOpenView,
    filters: {
      setPage,
      handleSearch,
    },
  };
};

export default useApplication;
