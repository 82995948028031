import { useEffect } from "react";
import PreLayout from "Component/Layout/PreLayout";
import { CircularProgress } from "@mui/material";
import { useVerifyEmailQuery } from "Network/Auth/auth";
import { Link, useSearchParams } from "react-router-dom";
import { useParams, useNavigate } from "react-router";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const state = useVerifyEmailQuery(searchParams.get("hash"));
  useEffect(() => {
    if (state.isSuccess) {
      toast.success("Email verified");
      navigate(`/${params.shortName}`);
    }
  }, [state.isLoading]);

  return (
    <PreLayout title={"Verification"} className="max-w-[30vw] sm:max-w-full">
      <div className="min-h-[30vh] w-full bg-white px-8 py-5 flex flex-col gap-5 mt-3">
        {state.isLoading ? (
          <div className="flex flex-col gap-4 w-full justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex flex-col gap-4 w-full items-center">
            {state.isError && (
              <p className="text-center text-[14px] mb-3 cursor-pointer">
                {state.error?.data?.message}
              </p>
            )}

            <div className="flex flex-col items-center gap-2">
              <Link to={`/${params.shortName}/resend-verify-email`}>
                <p className="text-[14px] text-primary font-semibold">
                  Resend Link
                </p>
              </Link>

              <Link to={`/${params.shortName}`}>
                <p className="text-[14px] text-primary font-semibold">Log in</p>
              </Link>
            </div>
          </div>
        )}
      </div>
    </PreLayout>
  );
};

export default VerifyEmail;
