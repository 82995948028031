import React from "react";
import CustomModal from "Component/Forms/CustomModal";
import { useFormik } from "formik";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import * as Yup from "yup";
import { useEditClassMutation } from "Network/classes/class";
import { toast } from "react-toastify";

const EditClassModal = ({ open, setOpen, data }) => {
  const [editClass, editClassState] = useEditClassMutation();

  const initialValues = {
    name: data?.name || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Category name is required"),
  });
  const handleSubmit = (values) => {
    editClass({ data: values, id: data?._id })
      .unwrap()
      .then(() => {
        setOpen(false);
        toast.success("Class updated successfully");
      })
      .catch((err) => {
        if (err.status === 400) toast.error(err.data?.message);
        else toast.error("Something went wrong");
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <CustomModal
      title="Edit Class"
      open={open}
      handleClose={() => !editClassState.isLoading && setOpen(false)}
      modalClassName="min-h-[100px] w-[35%] sm:h-[165px] sm:top-32"
    >
      <div className="flex flex-col h-full mt-5 gap-4 w-full ">
        <InputNoFormik
          withTitle
          title="Class Name"
          value={formik.values.name}
          name="name"
          handleChange={formik.handleChange}
          showError
          error={formik.errors.schoolName}
          touched={formik.touched.schoolName}
        />

        <div className="flex justify-between">
          <Button
            handleSubmit={() => setOpen(false)}
            title="Cancel"
            className="w-fit text-[12px] py-2 px-4 font-medium"
            variant="outline"
          />

          <Button
            handleSubmit={formik.handleSubmit}
            title="Update"
            className="w-fit text-[12px] py-2 px-4 font-medium"
            isLoading={editClassState.isLoading}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default EditClassModal;
