import Select from "Component/Forms/Select";
import CustomModal from "Component/Forms/CustomModal";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useEditApplication from "../hooks/useEditApplication";
import { transformSelectOption, transformClassOption } from "Utils/transform";

const EditApplication = ({ open, setOpen, data }) => {
  const {
    sections,
    documents,
    classes,

    selectedClasses,
    setSelectedClasses,

    selectedDocument,
    setSelectedDocument,

    selectedPaymentMode,
    setSelectedPaymentMode,

    selectedSection,
    setSelectedSection,

    formik,
    editApplicationsState,
    paymentOptions,

    selectedRequirement,
    setSelectedRequirement,
    entryRequirements,

    attachments,
    selectedAttachment,
    setSelectedAttachment,
  } = useEditApplication(data, setOpen);

  return (
    <CustomModal
      open={open}
      handleClose={() => !editApplicationsState.isLoading && setOpen(false)}
      title="Edit Application"
      className="w-[35%] sm:w-[95%] max-h-[90%] sm:max-h-[95%] overflow-y-auto"
    >
      <div className="flex flex-col h-full mt-5 gap-4 w-full ">
        <InputNoFormik
          withTitle
          title="Admission Title"
          value={formik.values.description}
          name="description"
          handleChange={formik.handleChange}
          showError
          error={formik.errors.description}
          touched={formik.touched.description}
        />
        <Select
          label="Section"
          className={`${formik.errors?.sectionId && "!border-[red] "}`}
          mainContainerStyle="sm:w-[100%]  text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          data={transformSelectOption(sections?.data)}
          value={selectedSection}
          isLoading={sections.isFetching}
          onChangeOption={(x) => {
            formik.setFieldValue("sectionId", x?.value);
            setSelectedSection(x);
          }}
        />
        <Select
          label="Classes"
          className={`${formik.errors?.classes && "!border-[red] "}`}
          mainContainerStyle="sm:w-[100%] w-[100%] text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          selectMultiple
          data={transformClassOption(classes?.data)}
          value={selectedClasses}
          isLoading={classes.isFetching}
          onChangeOption={(x) => {
            formik.setFieldValue(
              "classes",
              x.map((eachClass) => eachClass?.value),
            );
            setSelectedClasses(x);
          }}
        />
        <InputNoFormik
          withTitle
          type="number"
          title="Fees"
          value={formik.values.fee}
          name="fee"
          handleChange={formik.handleChange}
          showError
          error={formik.errors.fee}
          touched={formik.touched.fee}
        />
        <Select
          label="Supporting document"
          className={`${formik.errors?.supportingDocuments && "!border-[red] "}`}
          mainContainerStyle="sm:w-[100%] w-[100%] text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          selectMultiple
          data={transformSelectOption(documents?.data)}
          value={selectedDocument}
          isLoading={documents.isFetching}
          onChangeOption={(x) => {
            formik.setFieldValue(
              "supportingDocuments",
              x.map((eachDoc) => eachDoc?.value),
            );
            setSelectedDocument(x);
          }}
        />
        <Select
          label="Entry Requirement"
          className={`${formik.errors?.entryRequirements && "!border-[red] "}`}
          mainContainerStyle="sm:w-[100%] w-[100%] text-[14px]"
          selectMultiple
          data={transformSelectOption(
            entryRequirements?.data,
            "requirementText",
          )}
          value={selectedRequirement}
          isLoading={entryRequirements.isFetching}
          onChangeOption={(x) => {
            formik.setFieldValue(
              "entryRequirements",
              x.map((item) => item?.value),
            );
            setSelectedRequirement(x);
          }}
        />

        <Select
          label="Attach Document"
          className={`${formik.errors?.attachments && "!border-[red] "}`}
          mainContainerStyle="w-[100%] text-[14px]"
          selectMultiple
          data={transformSelectOption(attachments?.data)}
          value={selectedAttachment}
          isLoading={attachments.isFetching}
          onChangeOption={(x) => {
            formik.setFieldValue(
              "attachments",
              x.map((item) => item?.value),
            );
            setSelectedAttachment(x);
          }}
        />
        <Select
          label="Payment type"
          className={`${formik.errors?.paymentMode && "!border-[red] "}`}
          mainContainerStyle="w-[100%] text-[14px]"
          labelClass="text-[14px]"
          optionClass="z-50"
          data={paymentOptions}
          value={selectedPaymentMode}
          onChangeOption={(x) => {
            formik.setFieldValue("paymentMode", x.value);
            setSelectedPaymentMode(x);
          }}
        />
        <div className="flex justify-between">
          <Button
            handleSubmit={() => setOpen(false)}
            title="Cancel"
            className="w-fit text-[12px] py-2 px-4 font-medium"
            variant="outline"
          />

          <Button
            handleSubmit={formik.handleSubmit}
            title="Update"
            className="w-fit text-[12px] py-2 px-4 font-medium"
            isLoading={editApplicationsState.isLoading}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default EditApplication;
