import { create } from "zustand";
import { persist } from "zustand/middleware";

import { currentApp } from "../Utils/constants";

const useToggleActiveAppStore = create(
  persist(
    (set) => ({
      activeApp: currentApp.ACADEMICS,
      setActiveApp: (active) =>
        set({
          activeApp: active ? currentApp.ADMISSION : currentApp.ACADEMICS,
        }),
    }),
    {
      name: "activeApp",
    },
  ),
);

export default useToggleActiveAppStore;
