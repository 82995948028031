import { useState } from "react";
import CustomModal from "Component/Forms/CustomModal";
import { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import PropTypes from "prop-types";

const OneFieldUpdate = ({
  openUpdateModal,
  setOpenUpdateModal,
  title,
  isLoading,
  handleUpdate,
  label,
  value,
}) => {
  const [text, settext] = useState(value);

  const handleSubmit = () => {
    handleUpdate(text);
  };
  return (
    <CustomModal
      open={openUpdateModal}
      handleClose={() => !isLoading && setOpenUpdateModal(false)}
      modalClassName="min-h-[100px] sm:h-[195px] sm:top-32 w-[40%]"
    >
      <div>
        <h2 className="text-[20px] font-semibold">Edit {title}</h2>
        <div className="flex flex-col h-full mt-5 gap-4 w-full ">
          <InputNoFormik
            withTitle
            title={label}
            className="text-primary font-semibold"
            value={text}
            handleChange={(e) => settext(e.target.value)}
          />

          <div className="flex justify-between">
            <Button
              handleSubmit={() => setOpenUpdateModal(false)}
              title="Cancel"
              className="w-fit text-[12px] py-2 px-4"
              variant="outline"
            />

            <Button
              disabled={text === value || text === ""}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              title="Update"
              className="w-fit text-[12px] py-2 px-4"
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default OneFieldUpdate;

OneFieldUpdate.prototype = {
  openUpdateModal: PropTypes.bool,
  setOpenUpdateModal: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  handleUpdate: PropTypes.func,
  value: PropTypes.string,
};

OneFieldUpdate.defaultProps = {
  title: "",
  label: "",
  value: "",
  isLoading: false,
  handleUpdate: () => {},
};
