import MainLayout from "Component/Layout/MainLayout";

const AcademicsHome = () => {
  return (
    <MainLayout>
      <div>AcademicsHome</div>
    </MainLayout>
  );
};

export default AcademicsHome;
