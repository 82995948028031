import {
  MobileTableWrapper,
  MobileTRow,
  MobileTableLoader,
} from "Component/Table/Mobile";
import Actions from "../Manage/Actions";
import Avatar from "Component/Avatar";
import StatusIdentifier from "Component/StatusIdentifier";

const ApplicantMobileView = ({ applicantsState }) => {
  const { data: applicants, isFetching } = applicantsState;

  return (
    <div>
      {!isFetching ? (
        <MobileTableWrapper>
          {applicants?.data?.map((data, index) => (
            <div
              key={index}
              className="flex gap-5 px-3 py-2 rounded bg-white  shadow-small-select  text-[14px]  "
            >
              <table className="w-full">
                <MobileTRow
                  title={"Applicant ID"}
                  actionTrigger={<Actions data={data} />}
                >
                  <div className="flex gap-2 items-center py-[6px] w-full h-full">
                    <Avatar image={data?.applicant?.photo} />
                    <span className="">
                      {data?.applicant?.applicantNumber || "Applicant ID"}
                    </span>
                  </div>
                </MobileTRow>

                <MobileTRow
                  action
                  title={"Name"}
                  text={`${data?.applicant?.firstName} ${data?.applicant?.middleName || ""} ${data?.applicant?.lastName}`}
                />

                <MobileTRow title={"Section"} text={data?.section?.name} />

                <MobileTRow title={"Class"} text={data?.class?.name} />

                <MobileTRow title={"Payment Mode"} text={data?.mode} />

                <MobileTRow title={"Payment Status"}>
                  <StatusIdentifier status={data?.status} />
                </MobileTRow>

                <MobileTRow title={"Admission Status"}>
                  <StatusIdentifier status={data?.applicant?.admissionStatus} />
                </MobileTRow>
              </table>
            </div>
          ))}
        </MobileTableWrapper>
      ) : (
        <MobileTableLoader />
      )}
    </div>
  );
};

export default ApplicantMobileView;
