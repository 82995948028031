const PictureCard = ({ imagePath, text }) => {
  return (
    <div
      className="w-[326px] sm:w-[141px] sm:h-[209px] h-[481px] sm:bg-contain rounded-t-[74px] sm:rounded-t-[10px] border-[6px] sm:border-[2px] border-border-red flex flex-col justify-end bg-auto bg-no-repeat"
      style={{
        backgroundImage: `url(${imagePath})`,
      }}
    >
      <div className="w-full h-[30%] bg-custom-gradient flex items-center justify-center relative">
        <span className="text-white text-[35px] sm:text-[16px] font-semibold">
          {text}
        </span>
      </div>
    </div>
  );
};

export default PictureCard;
