import { useNavigate } from "react-router-dom";
import finish from "Assets/email1.png";
import { useSelector } from "react-redux";
import { navigationRoutes } from "Utils/navigationRoutes";
import { replaceWhiteSpaces } from "Utils";

const Sucess = () => {
  const reg = useSelector((state) => state.appSlice);

  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_WEB_BASE_URL;

  return (
    <div className=" min-h-screen  bg-main-bg sm:bg-white pt-10 sm:pt-10">
      <div
        className={`w-[50vw] mt-10 sm:w-[100vw] m-auto bg-white px-5 py-5 flex flex-col items-center `}
      >
        <div className=" mt-3">
          <h1 className="font-bold text-primary text-[20px] sm:text-[14px] p-2">
            Congratulations Your Registration was Successful !
          </h1>
        </div>
        <div className="flex flex-col justify-center">
          <img src={finish} alt="" className="h-[300px]" />
          <h1 className="font-bold text-[18px] text-center">
            {" "}
            Verify your Email
          </h1>
        </div>
        <div className="flex flex-col   p-2 sm:text-[12px]">
          <p className="text-slate-800 ">
            Hi Welcome to SoftSchoole, Kindly Check your email to confirm your
            account
          </p>
          <span className=" mt-6">Didn't recive confirmation link yet ? </span>
          <p
            className="text-primary underline md:text-[16px] cursor-pointer"
            onClick={() =>
              navigate(
                `/${reg?.shortName}${navigationRoutes.RESEND_VERIFY_EMAIL}`,
              )
            }
          >
            click here
          </p>
          <p
            className="text-primary underline md:text-[16px] mt-2 cursor-pointer"
            onClick={() =>
              navigate(`/${reg?.shortName}${navigationRoutes.LOGIN}`)
            }
          >
            {BASE_URL}/{replaceWhiteSpaces(reg?.shortName?.toLocaleLowerCase())}
          </p>
          <p className="text-[12px] italic font-bold text-red-600 p-2">
            ***Always use the link to access your portal
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sucess;
