import { RxDashboard } from "react-icons/rx";
import NavItem, { DropDownNavItem } from "./NavItem";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { FiSettings, FiUsers } from "react-icons/fi";
import { FcDataConfiguration } from "react-icons/fc";
import { TbCurrencyNaira } from "react-icons/tb";
import { admissionRoute, generalSettingsRoute } from "Utils/navigationRoutes";

const AddNav = ({ toggle }) => {
  return (
    <div
      className="flex flex-col gap-5 overflow-y-auto h-full pb-3"
      style={{ scrollbarWidth: "thin" }}
    >
      <NavItem
        Icon={RxDashboard}
        text={"Dashboard"}
        active={admissionRoute.dashboard}
        toggle={toggle}
      />
      <NavItem
        Icon={FiUsers}
        text={"Applicants"}
        active={admissionRoute.applicants}
        toggle={toggle}
      />
      <NavItem
        Icon={HiOutlineClipboardDocumentCheck}
        text={"Applications"}
        active={admissionRoute.applications}
        toggle={toggle}
      />

      <NavItem
        Icon={TbCurrencyNaira}
        text={"Payment Verification"}
        active={admissionRoute.paymentVerification}
        toggle={toggle}
      />

      <DropDownNavItem
        Icon={FcDataConfiguration}
        text={"Configurations"}
        toggle={toggle}
        active={admissionRoute.settings}
        items={[
          {
            name: "Admission template",
            link: admissionRoute.admissionTemplate,
          },

          {
            name: "Supporting Document",
            link: admissionRoute.supportingDocument,
          },

          {
            name: "Entry Requirement",
            link: admissionRoute.admissionEntryRequirement,
          },

          {
            name: "Applicant Form Builder",
            link: admissionRoute.applicantFormBuilder,
          },
        ]}
      />
      <DropDownNavItem
        Icon={FiSettings}
        text={"General Settings"}
        toggle={toggle}
        active={generalSettingsRoute.mainPath}
        items={[
          {
            name: "School profile",
            link: generalSettingsRoute.schoolProfile,
          },
          {
            name: "Bank Control",
            link: generalSettingsRoute.bank,
          },

          {
            name: "Session control",
            link: generalSettingsRoute.session,
          },
          {
            name: "Section",
            link: generalSettingsRoute.section,
          },
          {
            name: "Category",
            link: generalSettingsRoute.category,
          },

          {
            name: "Classes",
            link: generalSettingsRoute.schoolClass,
          },

          {
            name: "Attachment Document",
            link: generalSettingsRoute.attachmentDocument,
          },
        ]}
      />
    </div>
  );
};

export default AddNav;
