import { create } from "zustand";

const useApplicationStore = create((set) => ({
  open: false,
  selectApplication: null,
  selectedClass: null,
  setOpen: (open) => set({ open }),
  setSelectedApplication: (application) =>
    set({ selectApplication: application }),
  setSelectedClass: (cl) => set({ selectedClass: cl }),

  clearStore: () => set({ selectApplication: null, selectedClass: null }),
}));

export default useApplicationStore;
