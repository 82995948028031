/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";

import { useCreateBankMutation } from "Network/Configurations/bank";
import { toast } from "react-toastify";

const useCreateBank = () => {
  const [createBank, bankMutationState] = useCreateBankMutation();
  useEffect(() => {
    if (bankMutationState.isError) toast.error("Something went wrong");
    if (bankMutationState.isSuccess) toast.success("New Payment Account set");
  }, [bankMutationState.isSuccess]);

  return { createBank, bankMutationState };
};

export default useCreateBank;
