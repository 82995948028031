import CustomModal from "Component/Forms/CustomModal";
import { Formik } from "formik";
import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import * as Yup from "yup";
import { useCreateEntryRequirementMutation } from "Network/Configurations/admissionEntryRequirement";
import { toast } from "react-toastify";

const CreateRequirement = ({
  openCreateEntryRequirement,
  setOpenCreateEntryRequirement,
}) => {
  const [createEntryRequirement, entryRequirementState] =
    useCreateEntryRequirementMutation();
  const initialValues = {
    requirementText: "",
  };

  const validationSchema = Yup.object().shape({
    requirementText: Yup.string().required("Requirement is required"),
  });

  const handleSubmit = (values, action) => {
    createEntryRequirement(values)
      .unwrap()
      .then((res) => {
        toast.success("Entry requirement created");
        setOpenCreateEntryRequirement(false);
        action.resetForm();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  return (
    <CustomModal
      open={openCreateEntryRequirement}
      title="Create Entry Requirement"
      handleClose={() =>
        !entryRequirementState.isLoading && setOpenCreateEntryRequirement(false)
      }
      modalClassName="min-h-[100px] sm:h-[165px] sm:top-32 w-[35%]"
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="flex flex-col h-full mt-5 gap-4 w-full ">
              <Input
                withTitle
                title="Requirement"
                placeholder="Enter Requirement"
                name="requirementText"
                className="text-primary font-semibold"
              />

              <Button
                isLoading={entryRequirementState.isLoading}
                handleSubmit={props.handleSubmit}
                title="Create"
                className="w-fit text-[12px] py-2 px-4"
              />
            </div>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default CreateRequirement;
