import { RxDashboard } from "react-icons/rx";
import MobileNavItem from "./MobileNavItem";
import { MdCancel } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setToggleMobileNav } from "../../../Store/AppSlice";

const MobileAcademicsNav = () => {
  const dispatch = useDispatch();

  return (
    <div
      className="flex flex-col gap-5 overflow-y-auto h-full pb-3"
      style={{ scrollbarWidth: "thin" }}
    >
      <MdCancel
        onClick={() => dispatch(setToggleMobileNav(false))}
        size={30}
        className="self-end"
      />
      <div className="flex flex-col gap-3">
        <MobileNavItem
          Icon={RxDashboard}
          text={"Dashboard"}
          active={"/dashboard"}
        />
      </div>
    </div>
  );
};

export default MobileAcademicsNav;
