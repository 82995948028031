import { useParams } from "react-router-dom";

import BannerImage from "Assets/admission-banner-image3.png";
import { useGetSchoolInfoQuery } from "Network/Auth/profile";

const HeaderBanner = () => {
  const params = useParams();
  const schoolState = useGetSchoolInfoQuery(params.shortName);

  return (
    <div className="relative">
      <img
        src={BannerImage}
        alt="banner"
        className="w-full h-[350px] sm:h-[180px] object-cover"
      />
      <div className="absolute w-full h-full top-0 bg-black bg-opacity-60 flex items-center ">
        <div className="text-white font-bold text-3xl sm:text-sm ml-44 sm:ml-10 flex flex-col gap-2 sm:gap-0">
          <p> Welcome to,</p>
          <p>{schoolState?.data?.schoolName}</p>
          <p>Admission Portal</p>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
