import Button from "./Forms/Button";
import { useNavigate } from "react-router-dom";

const ComingSoon = ({ title, buttonTitle, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        You're not lost!
      </h1>
      <p className="text-lg text-gray-600">This is "{title}", coming soon.</p>
      {buttonTitle && (
        <Button
          className="bg-blue-500 text-white px-6 py-2 mt-2 rounded hover:bg-blue-600 transition duration-300"
          title={buttonTitle}
          handleSubmit={() => navigate(navigateTo)}
        />
      )}
    </div>
  );
};

export default ComingSoon;
