import MainLayout from "Component/Layout/MainLayout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Template1 from "./Templates/Template1";
import Button from "Component/Forms/Button";
import useGetTemplate from "../hooks/useGetTemplate";
import useSetTemplate from "../hooks/useSetTemplate";
import { admissionTemplateTabs } from "Utils/constants";

const AdmissionTemplate = () => {
  const { tempQuery, setSelected, selected, isActive, selectedTitle } =
    useGetTemplate();

  const { tempMutationState, handleSetTemplate } = useSetTemplate();

  return (
    <MainLayout isLoading={tempQuery.isLoading}>
      <Tabs
        defaultIndex={Number(tempQuery?.data?.admission)}
        onSelect={(index) => setSelected(index)}
      >
        <TabList className=" p-2 rounded bg-white flex justify-between sm:flex-col sm:gap-3">
          <div>
            {admissionTemplateTabs.map((temp, index) => (
              <Tab key={index}>
                {temp}{" "}
                {index === Number(tempQuery?.data?.admission) && (
                  <div className=" bg-green-600 py-[2px] px-1 rounded-full text-[8px] w-[100%] text-white font-bold" />
                )}
              </Tab>
            ))}
          </div>
          {null && (
            <Button
              title={selectedTitle()}
              disabled={isActive()}
              handleSubmit={() => handleSetTemplate(selected)}
              isLoading={tempMutationState.isLoading}
              className="w-[150px] sm:w-full"
            />
          )}
        </TabList>

        <TabPanel>
          <Template1 />
        </TabPanel>
      </Tabs>
    </MainLayout>
  );
};

export default AdmissionTemplate;
