import { MobileTableWrapper, MobileTRow } from "Component/Table/Mobile";
import Button from "Component/Forms/Button";
import useApplicationStore from "../hooks/useApplicationStore";

const MobileContent = ({ application }) => {
  const { setOpen, setSelectedApplication, setSelectedClass } =
    useApplicationStore();

  return (
    <MobileTableWrapper>
      {application?.classes?.map((app, index) => (
        <div className="w-full bg-white px-2" key={index}>
          <table className="w-full text-[12px]">
            <MobileTRow
              title="Class"
              text={app?.name}
              actionTriggerClassName="flex justify-end py-2"
              actionTrigger={
                <Button
                  title="Apply"
                  className="sm:text-xs"
                  handleSubmit={() => {
                    setOpen(true);
                    setSelectedApplication(application);
                    setSelectedClass(app);
                  }}
                />
              }
            />
            <MobileTRow
              title="Category"
              text={app?.category?.name || "General"}
            />
          </table>
        </div>
      ))}
    </MobileTableWrapper>
  );
};

export default MobileContent;
