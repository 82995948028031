import { useState } from "react";
import { toast } from "react-toastify";

import { useUpdateApplicantsPaymentStatusMutation } from "Network/applicantPayment";

const useUpdateApplicantPaymentStatus = ({ handleCloseView }) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const [updateStatus, { isLoading }] =
    useUpdateApplicantsPaymentStatusMutation();

  const handleUpdateStatus = ({ applicantId, status }) => {
    updateStatus({ applicantId, status })
      .unwrap()
      .then((_) => {
        toast.success("Status Updated Successfully");
        setOpenApprove(false);
        setOpenReject(false);
        handleCloseView();
      })
      .catch((err) => {
        if (err.status === 400) {
          setOpenApprove(false);
          setOpenReject(false);
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return {
    handleUpdateStatus,
    isLoading,
    openApprove,
    openReject,
    setOpenApprove,
    setOpenReject,
  };
};

export default useUpdateApplicantPaymentStatus;
