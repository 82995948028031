import { Formik } from "formik";
import * as Yup from "yup";

import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useAuthStore, {
  AuthModalPages,
} from "Screens/SchoolWebpages/hooks/useAuthStore";
import { useForgotPasswordMutation } from "Network/Auth/password";

const ForgetPassword = () => {
  const { setPage } = useAuthStore();

  const [sendForgotPassword, forgotPasswordState] = useForgotPasswordMutation();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = (values) => {
    console.log(values);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="min-h-[30vh] w-full bg-white mt-3">
          {forgotPasswordState.isUninitialized ||
          !forgotPasswordState.isSuccess ? (
            <div className="flex flex-col gap-4 w-full">
              <p className="text-center text-[14px] text-primary font-semibold cursor-pointer">
                Enter your email address to reset your password
              </p>

              <Input
                withTitle
                title="Email"
                placeholder="Enter email"
                name="email"
              />

              <div className="flex justify-between sm:gap-10">
                <Button
                  title="Close"
                  className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                />

                <Button
                  isLoading={forgotPasswordState.isLoading}
                  handleSubmit={props.handleSubmit}
                  title="Reset"
                  className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] mt-[15px] "
                />
              </div>

              <p className="text-center text-[14px] font-semibold">
                Back to{" "}
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => setPage(AuthModalPages.LOGIN)}
                >
                  Login
                </span>
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-6">
              <p className="text-center text-[14px]">
                If account exist with this email, a password reset link has been
                send to it.
              </p>

              {!forgotPasswordState.isLoading ? (
                <p
                  onClick={props.handleSubmit}
                  className="cursor-pointer text-center text-[14px] text-primary font-semibold"
                >
                  Resend Link?
                </p>
              ) : (
                <p className="text-center text-[14px] text-primary font-semibold">
                  ...
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </Formik>
  );
};

export default ForgetPassword;
