import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import FirstScreen from "./FirstScreen";
import SecondScreen from "./SecondScreen";
import Sucess from "./Sucess";
import useRegistration from "hooks/Auth/useRegistration";
import { userRole } from "Utils/constants";
import Layout from "./Layout";
import { replaceWhiteSpaces } from "Utils";

const SignUpScreen = () => {
  const success = useSelector((state) => state.appSlice?.registrationSuccess);
  const [currentScreen, setCurrentScreen] = useState(0);
  const { handleRegister, loading } = useRegistration();

  const initialValues = {
    schoolName: "",
    shortName: "",
    address: "",
    localGovernment: "",
    country: "",
    state: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };

  const firstScreenSchema = Yup.object().shape({
    schoolName: Yup.string().required("School name is required"),
    shortName: Yup.string().required("Short name is required"),
    address: Yup.string().required("Address is required"),
    localGovernment: Yup.string().required("Local government is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
  });

  const secondScreenSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string().required("Contact is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const validationSchemas = [firstScreenSchema, secondScreenSchema];

  const handleNext = () => {
    setCurrentScreen(1);
  };

  const handleSubmitForm = (values) => {
    const body = {
      school: {
        schoolName: values.schoolName,
        shortName: replaceWhiteSpaces(values.shortName.toLocaleLowerCase()),
        state: values.state,
        country: values.country,
        localGovernment: values.localGovernment,
        contact: values.phone,
        address: values.address,
      },
      admin: {
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        email: values.email,
        role: userRole.ADMIN,
      },
    };
    handleRegister(body);
  };
  const handleSubmit = (values) => {
    if (currentScreen === 1) {
      handleSubmitForm(values);
    } else {
      handleNext();
    }
  };

  return (
    <div>
      {success ? (
        <Sucess />
      ) : (
        <Layout>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas[currentScreen]}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <div className="min-h-[30vh] w-full bg-white shadow-lg px-8 py-5 flex flex-col gap-5 mt-3">
                <div className="flex gap-3 justify-center">
                  {Array.from({ length: 2 }).map((_, index) => (
                    <p
                      className={`px-4 py-[3px] ${index === currentScreen ? "bg-primary" : "border-1 border-primary"}  rounded-lg`}
                    />
                  ))}
                </div>
                {currentScreen === 0 ? (
                  <FirstScreen formikProps={props} />
                ) : (
                  <SecondScreen
                    back={() => {
                      setCurrentScreen(0);
                    }}
                    formikProps={props}
                    loading={loading}
                  />
                )}
              </div>
            )}
          </Formik>
        </Layout>
      )}
    </div>
  );
};

export default SignUpScreen;
