import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppToast from "./Component/Toaster/AppToast";

import AdmissionRoutes from "./Routes/AdmissionRoutes";
import AcademicRoutes from "./Routes/AcademicRoutes";
import SchoolWebpagesRoutes from "Routes/SchoolWebpagesRoutes";
import WebsiteRoutes from "Routes/WebsiteRoutes";
import NotFoundPage from "Component/NotFoundPage";
import SharedRoutes from "Routes/SharedRoutes";
import GeneralRoutes from "Routes/GeneralRoutes"; //

function App() {
  return (
    <div>
      <AppToast />

      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          {SharedRoutes}
          {SchoolWebpagesRoutes}
          {GeneralRoutes}

          {WebsiteRoutes}
          {AdmissionRoutes}
          {AcademicRoutes}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
