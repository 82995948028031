import { IoEyeOutline } from "react-icons/io5";
import { HiOutlineDocumentArrowUp } from "react-icons/hi2";

import { PopOver, PopOverList } from "Component/PopOver";
import { ActionTriggerIcon } from "Component/Table";
import usePaymentFilters from "../hooks/usePaymentFilters";

const Actions = ({ data }) => {
  const { setOpenView, setApplicants, setOpenUpload } = usePaymentFilters();
  return (
    <PopOver
      className="w-[180px] text-[12px]"
      header={<ActionTriggerIcon />}
      content={
        <PopOverList
          className="px-2"
          items={[
            {
              name: "View",
              icon: <IoEyeOutline size={16} />,
              onClick: () => {
                setOpenView(true);
                setApplicants(data);
              },
            },

            {
              name: "Upload proof",
              icon: <HiOutlineDocumentArrowUp size={16} />,
              onClick: () => {
                setOpenUpload(true);
                setApplicants(data);
              },
            },
          ]}
        />
      }
    />
  );
};

export default Actions;
