import Logo from "Component/Logo";
import Button from "Component/Forms/Button";
import useAuthStore, { AuthModalPages } from "../hooks/useAuthStore";
import { isParentLoggedIn } from "./utils";

const Header = ({ schoolLogo }) => {
  const { setOpen, setPage } = useAuthStore();
  const isLoggedIn = isParentLoggedIn();

  const handleOpenModal = (page) => {
    setOpen(true);
    setPage(page);
  };
  return (
    <div className="h-[79px] sm:h-[50px] w-full sm:mx-0 sm:min-w-[100vw] min-w-[850px] max-w-[1500px] px-24 sm:px-4 flex justify-between items-center fixed z-20 top-0 bg-white">
      <Logo image={schoolLogo} />

      {!isLoggedIn ? (
        <div className="flex gap-2">
          <Button
            title="Create account"
            variant="text"
            handleSubmit={() => handleOpenModal(AuthModalPages.SIGNUP)}
            className=" sm:text-xs sm:h-[25px]"
          />
          <Button
            title="Login"
            handleSubmit={() => handleOpenModal(AuthModalPages.LOGIN)}
            className="w-[98px] h-[39px] sm:text-xs sm:h-[25px] sm:w-[70px]"
          />
        </div>
      ) : (
        <Button
          title="Go to dashboard"
          variant="outline"
          handleSubmit={() => {}}
          className=" sm:text-xs sm:h-[25px] text-primary"
        />
      )}
    </div>
  );
};

export default Header;
