import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { tags } from "../Utils/constants";

const api = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("school_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    tags.SESSION_CONTROL_TAG,
    tags.TEMPLATE_CONTROL_TAG,
    tags.PAYMENT_CONTROL_TAG,
    tags.MY_PROFILE_CONTROL_TAG,
    tags.SCHOOL_PROFILE_CONTROL_TAG,
    tags.SCHOOL_SECTIONS,
    tags.SCHOOL_CATEGORIES,
    tags.SCHOOL_CLASS,
    tags.SCHOOL_SUPPORTING_DOCUMENT,
    tags.SCHOOL_APPLICANTS,
    tags.SCHOOL_APPLICATIONS,
    tags.BANK_CONTROL_TAG,
    tags.ADMISSION_ENTRY_REQUIREMENT,
    tags.ATTACHMENT,
  ],
});

export default api;
