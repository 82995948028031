import * as Yup from "yup";

export const profileInitialValues = {
  schoolName: "",
  state: "",
  country: "",
  localGovernment: "",
  contact: "",
  address: "",
  motto: "",
};

export const profileValidationSchema = Yup.object().shape({
  schoolName: Yup.string().required("School Name is required"),
  motto: Yup.string().required("Motto is required"),
  address: Yup.string().required("Address is required"),
  contact: Yup.string().required("Contact is required"),
  state: Yup.string().required("State is required"),
  localGovernment: Yup.string().required("Local gov't is required"),
  country: Yup.string().required("Country is required"),
});
