import cn from "../Utils/cn";
import { statusConstant } from "../Utils/constants";

const statusConfig = {
  [statusConstant.pending]: {
    text: "Pending",
    className: "text-yellow-700 bg-yellow-100",
  },

  [statusConstant.admitted]: {
    text: "Admitted",
    className: "text-green-900 bg-green-100",
  },
  [statusConstant.successful]: {
    text: "Successful",
    className: "text-green-900 bg-green-100",
  },
  [statusConstant.declined]: {
    text: "Declined",
    className: "text-red-700 bg-red-100",
  },

  [statusConstant.failed]: {
    text: "Failed",
    className: "text-red-700 bg-red-100",
  },

  [statusConstant.published]: {
    text: "Published",
    className: "text-green-900 bg-green-100",
  },

  [statusConstant.notPublish]: {
    text: "Not Published",
    className: "text-red-700 bg-red-100",
  },
};

const StatusIdentifier = ({ status, className }) => {
  const config = statusConfig[status];

  if (!config) {
    return null; // Return null if the status is not recognized
  }

  return (
    <BaseStatus
      className={cn(config.className, className)}
      text={config.text}
    />
  );
};

const BaseStatus = ({ className, text }) => {
  return (
    <span className={cn("rounded p-[4px] text-[14px]", className)}>{text}</span>
  );
};

export default StatusIdentifier;
