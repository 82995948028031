import { useState, useEffect, useCallback } from "react";
import { getNigeriaStates } from "geo-ng";

import { useGetSchoolProfileQuery } from "../../../../../Network/Auth/profile";
import { countryList } from "../../../../../Utils/constants";
import capitalizeFirstLetter from "../../../../../Utils/capitalizeFirstLetter";

const useGetSchoolprofile = (formik) => {
  const nigeriaStates = getNigeriaStates();

  const [country, setCountry] = useState({
    value: null,
    label: "Choose",
  });

  const [state, setState] = useState({
    value: null,
    label: "Choose",
  });

  const [localGovernment, setLocalGovernment] = useState({
    value: null,
    label: "Choose",
  });

  const profileState = useGetSchoolProfileQuery();
  const [localGovernmentList, setLocalGovernmentList] = useState([]);

  const stateList = nigeriaStates.map((st) => {
    const stateName = capitalizeFirstLetter(st.name.toLocaleLowerCase());
    return { value: stateName, label: stateName };
  });

  const getLgas = useCallback(
    (selectedState) => {
      if (selectedState) {
        const st = nigeriaStates.filter(
          (lgState) =>
            lgState.name.toLocaleLowerCase() ===
            selectedState?.toLocaleLowerCase(),
        );
        const lgas = st[0]?.lgas?.map((lga) => {
          const lgaName = capitalizeFirstLetter(lga.toLocaleLowerCase());
          return { value: lgaName, label: lgaName };
        });
        setLocalGovernmentList(lgas);

        return lgas;
      }
      return [];
    },
    [nigeriaStates],
  );

  const resetForm = () => {
    formik.setFieldValue("schoolName", profileState?.data?.schoolName);
    formik.setFieldValue("motto", profileState?.data?.motto ?? "");
    formik.setFieldValue("contact", profileState?.data?.contact);
    formik.setFieldValue("address", profileState?.data?.address ?? "");

    if (profileState?.data?.country) {
      formik.setFieldValue("country", profileState?.data?.country);
      setCountry(
        countryList.filter(
          (cn) => cn.value === profileState?.data?.country.toLowerCase(),
        )[0],
      );
    }

    if (profileState?.data?.state) {
      formik.setFieldValue("state", profileState?.data?.state);
      setState(
        stateList.filter(
          (cn) =>
            cn.value.toLowerCase() === profileState?.data?.state.toLowerCase(),
        )[0],
      );
    }

    if (profileState?.data?.localGovernment) {
      formik.setFieldValue(
        "localGovernment",
        profileState?.data?.localGovernment,
      );
      setLocalGovernment(
        getLgas(formik.values.state).filter(
          (cn) =>
            cn.value.toLocaleLowerCase() ===
            profileState?.data?.localGovernment.toLowerCase(),
        )[0],
      );
    }
  };

  useEffect(() => {
    if (profileState.isSuccess) {
      resetForm();
    }
  }, [profileState.isSuccess]);

  return {
    profileState,
    country,
    setCountry,
    state,
    setState,
    localGovernment,
    setLocalGovernment,
    resetForm,
    countryList,
    stateList,
    localGovernmentList,
  };
};

export default useGetSchoolprofile;
