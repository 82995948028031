import { flattenRoutes } from "Utils";

const admission = {
  mainPath: "admissions",
  childPath: {
    dashboard: "dashboard",
    paymentVerification: "payment-verification",

    applications: {
      main: "applications",
      childPath: {
        createApplications: "create-applications",
      },
    },
    applicants: {
      main: "applicants",
      childPath: {
        createApplicant: "create-applicant",
      },
    },

    settings: {
      main: "settings",
      childPath: {
        admissionTemplate: "admissions-template",
        supportingDocument: "supporting-docs",
        admissionEntryRequirement: "entry-requirement",
        applicantFormBuilder: "applicant-form-builder",
      },
    },
  },
};
const academic = {
  mainPath: "academics",
  childPath: {
    dashboard: "dashboard",
  },
};

const schoolWebpages = {
  mainPath: "",
  childPath: {
    admissions: {
      main: "admissions",
    },
  },
};

const generalSettings = {
  mainPath: "general-settings",
  childPath: {
    schoolProfile: "school-profile",
    profile: "profile",
    bank: "bank",
    session: "session",
    section: "section",
    category: "category",
    attachmentDocument: "attachment-document",
    schoolClass: "class",
    classes: "class/all",
  },
};

export const admissionRoute = flattenRoutes(admission);
export const academicRoute = flattenRoutes(academic);
export const schoolWebpagesRoute = flattenRoutes(schoolWebpages);
export const generalSettingsRoute = flattenRoutes(generalSettings);

export const navigationRoutes = {
  SHORTNAME: "/:shortName",
  SIGNUP: "/signup",
  LOGIN: "/login",

  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_EMAIL: "/verifying",
  RESEND_VERIFY_EMAIL: "/resend-verify-email",

  ADMISSION_PAGE: "/admissions",
};
