import { toast } from "react-toastify";

import CustomModal from "Component/Forms/CustomModal";
import Button from "Component/Forms/Button";
import FileUploader from "Component/Forms/FileUploader";
import useUploadFile from "../../../../hooks/File/useUploadFile";
import { useUploadApplicantsProofOfPaymentMutation } from "Network/applicantPayment";

const UploadProof = ({ open, applicant, handleClose = () => {} }) => {
  const [handleUpload, uploadState] = useUploadFile();

  const handleCloseAndClearFileCache = () => {
    uploadState.resetFile();
    handleClose();
  };

  const [uploadProof, { isLoading }] =
    useUploadApplicantsProofOfPaymentMutation();

  const handleUploadProof = ({ applicantId, proofOfPayment }) => {
    uploadProof({ applicantId, proofOfPayment })
      .unwrap()
      .then((_) => {
        toast.success("Upload Successfully");
        handleCloseAndClearFileCache();
      })
      .catch((err) => {
        if (err.status === 400) {
          handleCloseAndClearFileCache();
          toast.error(err.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return (
    <CustomModal
      className="max-w-[684px] h-[75vh] flex flex-col justify-between"
      open={open}
      handleClose={!uploadState.isLoading && handleCloseAndClearFileCache}
      title="Upload proof of payment"
    >
      <FileUploader
        disabled={isLoading}
        exceedSize={uploadState.exceedSize}
        showFileNotAllowedError={uploadState.fileNotAllowed}
        isLoading={uploadState.isLoading}
        title=" Drag &amp; drop here or Click"
        onFileUpload={(file) => handleUpload(file[0])}
      />

      <div className="flex justify-between w-full">
        <Button
          className="w-[121px] sm:w-[48%] py-2"
          title="Cancel"
          variant="outline"
          handleSubmit={handleCloseAndClearFileCache}
        />
        <Button
          className="w-[121px] sm:w-[48%] py-2"
          disabled={uploadState?.data === null || uploadState.isLoading}
          title="Upload"
          handleSubmit={() =>
            handleUploadProof({
              applicantId: applicant?._id,
              proofOfPayment: uploadState?.data?.src,
            })
          }
        />
      </div>
    </CustomModal>
  );
};

export default UploadProof;
