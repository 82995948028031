import Item from "./Item";

const List = () => {
  return (
    <div className="w-full flex flex-col items-center gap-8 sm:gap-5 py-5 sm:py-3  ">
      <p className="text-xl sm:text-[16px] font-semibold">
        List of open Admissions
      </p>

      <div className="w-[80%] sm:w-[95%] sm:mx-auto p-8 sm:p-0 sm:bg-transparent bg-white">
        <Item />
      </div>
    </div>
  );
};

export default List;
