import Logo from "Component/Logo";

const FooterColumn = ({ title = "Quick Links", useLogo = false, children }) => {
  return (
    <div className="flex flex-col gap-2">
      {!useLogo && (
        <p className="text-[20px] sm:text-[14px] font-semibold">{title}</p>
      )}
      {useLogo && <Logo variant="white" />}
      {children}
    </div>
  );
};

export default FooterColumn;
