import React from "react";

const Footer = () => {
  return (
    <div className="p-2 font-thin text-slate-500  text-center text-[12px]">
      <span>
        Copyright © 2021 - 2023 SoftSchoole All rights reserved. V1.0
      </span>
    </div>
  );
};

export default Footer;
