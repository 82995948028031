import { Route } from "react-router-dom";
import { navigationRoutes, admissionRoute } from "Utils/navigationRoutes";

import Dashboard from "Screens/AdmissionScreen/Dashboard";

import Applications from "Screens/AdmissionScreen/Applications";
import CreateApplications from "Screens/AdmissionScreen/Applications/Manage/CreateApplications";

import CreateApplicant from "Screens/AdmissionScreen/Applicants/Manage/CreateApplicant";
import Applicant from "Screens/AdmissionScreen/Applicants";
import ApplicantDetails from "Screens/AdmissionScreen/Applicants/View/ApplicantDetails";

import ApplicantsPaymentVerification from "Screens/AdmissionScreen/ApplicantsPaymentVerification";

import AdmissionTemplate from "Screens/AdmissionScreen/Settings/AdmissionTemplate";
import SupportingDocument from "Screens/AdmissionScreen/Settings/SupportingDocument";
import AdmissionEntryRequirement from "Screens/AdmissionScreen/Settings/AdmissionEntryRequirement";
import ApplicantFormBuilder from "Screens/AdmissionScreen/Settings/ApplicantFormBuilder";

const AdmissionRoutes = (
  <Route path={navigationRoutes.SHORTNAME}>
    <Route path={admissionRoute.dashboard} element={<Dashboard />} />

    <Route path={admissionRoute.applications} element={<Applications />} />
    <Route
      path={admissionRoute.createApplications}
      element={<CreateApplications />}
    />

    <Route
      path={admissionRoute.paymentVerification}
      element={<ApplicantsPaymentVerification />}
    />
    <Route path={admissionRoute.applicants} element={<Applicant />} />
    <Route
      path={admissionRoute.createApplicant}
      element={<CreateApplicant />}
    />
    <Route
      path={`${admissionRoute.applicants}/:id`}
      element={<ApplicantDetails />}
    />

    <Route
      path={admissionRoute.admissionTemplate}
      element={<AdmissionTemplate />}
    />

    <Route
      path={admissionRoute.supportingDocument}
      element={<SupportingDocument />}
    />

    <Route
      path={admissionRoute.admissionEntryRequirement}
      element={<AdmissionEntryRequirement />}
    />

    <Route
      path={admissionRoute.applicantFormBuilder}
      element={<ApplicantFormBuilder />}
    />
  </Route>
);

export default AdmissionRoutes;
