import Logo from "Component/Logo";

const Layout = ({ children }) => {
  return (
    <div className=" min-h-[100vh] bg-main-bg sm:bg-white pt-16 sm:pt-10">
      <div className="w-[50vw] sm:w-[100vw] m-auto flex flex-col items-center ">
        <div className="flex flex-col items-center gap-2">
          <Logo />
          <p className="text-[14px] font-bold text-primary text-center">
            School Registration
          </p>
        </div>

        {children}
      </div>
    </div>
  );
};

export default Layout;
