import Logo from "Component/Logo";
import Button from "Component/Forms/Button";
import { PopOverSimple, PopOverList } from "Component/PopOver";
import { navigationRoutes } from "Utils/navigationRoutes";

const Header = () => {
  return (
    <div className="flex justify-between h-[87px] sm:h-[50px] items-center px-4 sticky w-full bg-white z-20 top-0">
      <Logo />

      <PopOverSimple
        className="mt-[55px] sm:mt-[45px] w-[140px]"
        header={
          <Button
            title="Get started"
            className=" rounded-lg sm:rounded-[4px] py-[10px] sm:py-[8px] px-5 sm:px-3 sm:text-[12px] font-bold"
          />
        }
        content={
          <PopOverList
            items={[
              {
                name: "School",
                linkToItem: navigationRoutes.SIGNUP,
              },
              {
                name: "Parent",
                linkToItem: process.env.REACT_APP_PARENT_PORTAL,
              },
            ]}
          />
        }
      />
    </div>
  );
};

export default Header;
