import { Formik } from "formik";

import Select from "Component/Forms/Select";
import CustomModal from "Component/Forms/CustomModal";
import Input, { InputNoFormik } from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import UploadImage from "./CreateApplicant/UploadImage";
import { genderList } from "Utils/constants";

import useEditApplicant from "../hooks/useEditApplicant";
import { transformClassOption } from "Utils/transform";

const EditApplicant = ({ open, setOpen }) => {
  const {
    applicants,
    applications,
    selectedGender,
    selectedApplication,
    selectedClass,
    singleApplication,
    initialValues,
    handleSubmit,
    validationShcema,
    setSelectedGender,
    setSelectedApplication,
    setSelectedClass,
    editApplicantState,
  } = useEditApplicant(setOpen);

  return (
    <CustomModal
      open={open}
      handleClose={() => !editApplicantState.isLoading && setOpen(false)}
      title="Edit Applicant"
      className="w-[70%] max-w-[70%] sm:w-[95%] sm:max-w-[95%] max-h-[90%] sm:max-h-[95%] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationShcema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="bg-white">
            <UploadImage
              defaultImage={applicants?.photo}
              onFileChanged={(e) => {
                props.setFieldValue("photo", e);
              }}
            />

            <div className="flex  flex-col gap-6 px-6 sm:px-0 py-6 pt-0 ">
              <div className="grid grid-cols-3 sm:flex sm:flex-col sm:gap-3 gap-4">
                <Input
                  withTitle
                  title="First Name"
                  placeholder="Enter first name"
                  name="firstName"
                />
                <Input
                  withTitle
                  title="Middle Name"
                  placeholder="Enter first name"
                  name="middleName"
                />
                <Input
                  withTitle
                  title="Last Name"
                  placeholder="Enter first name"
                  name="lastName"
                />
                <Input
                  withTitle
                  title="Date of Birth"
                  name="dob"
                  type="date"
                  inputStyle="py-2"
                />

                <Select
                  withLabel
                  label="Gender"
                  data={genderList}
                  value={selectedGender}
                  className={`${props.errors?.gender && "border-[red] "}`}
                  onChangeOption={(x) => {
                    props.setFieldValue("gender", x?.value);
                    setSelectedGender(x);
                  }}
                />

                <Input
                  withTitle
                  title="Address"
                  placeholder="Enter Address"
                  name="address"
                />

                <Select
                  mainContainerStyle="col-span-3"
                  withLabel
                  label="Application"
                  className={`${props.errors?.applicationId && "border-[red]"}`}
                  data={
                    applications?.data?.data?.map((x) => ({
                      value: x._id,
                      label: `${x.description}: ${x.session}`,
                    })) || []
                  }
                  isLoading={applications.isFetching}
                  value={selectedApplication}
                  onChangeOption={(x) => {
                    props.setFieldValue("applicationId", x?.value);
                    setSelectedApplication(x);
                    setSelectedClass({ value: null, label: "Select" });
                  }}
                />
                <div className="grid grid-cols-2 sm:flex sm:flex-col sm:gap-3 gap-4 col-span-3 ">
                  <InputNoFormik
                    withTitle
                    title="Section"
                    value={singleApplication?.application?.section?.name}
                    placeholder="Appliaction section will show here..."
                    disabled
                  />

                  <Select
                    withLabel
                    label="Class"
                    className={`${props.errors?.classId && "border-[red] "}`}
                    data={transformClassOption(
                      singleApplication?.application?.classes,
                    )}
                    value={selectedClass}
                    onChangeOption={(x) => {
                      props.setFieldValue("classId", x?.value);
                      setSelectedClass(x);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <h3 className="font-bold sm:text-[14px] sm:font-semibold">
                  Medical Information
                </h3>
                <Input
                  withTitle
                  title="Does the child have any medical challenges (state if any)"
                  placeholder="Enter medical challenges"
                  name="medicalInfo"
                />
              </div>

              <div className="flex flex-col gap-1">
                <h3 className="font-bold sm:text-[14px] sm:font-semibold">
                  Guardian/Parent Information
                </h3>
                <div className="grid grid-cols-2 sm:flex sm:flex-col sm:gap-3 gap-4">
                  <Input
                    withTitle
                    title="Full Name"
                    placeholder="Enter Full Name"
                    name="parentOrGuardianInfo.fullName"
                  />
                  <Input
                    withTitle
                    title="Phone Number"
                    placeholder="000-0000-0000"
                    name="parentOrGuardianInfo.phone"
                  />
                  <Input
                    withTitle
                    title="Relationship"
                    placeholder="Example: Father, Mother, Sibling etc"
                    name="parentOrGuardianInfo.relationShip"
                  />
                  <Input
                    withTitle
                    title="Email"
                    placeholder="Enter Email"
                    name="parentOrGuardianInfo.email"
                  />
                </div>
              </div>

              <div className="flex justify-between">
                <Button
                  handleSubmit={() => setOpen(false)}
                  title="Cancel"
                  className="w-fit text-[12px] py-2 px-4 font-medium"
                  variant="outline"
                />

                <Button
                  handleSubmit={props.handleSubmit}
                  title="Edit Applicant"
                  className="w-fit text-[12px] py-2 px-4 font-medium"
                  isLoading={editApplicantState.isLoading}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </CustomModal>
  );
};

export default EditApplicant;
