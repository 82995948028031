import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import MidHero from "./components/MidHero";
import Testimonials from "./components/Testimonials";
import Faq from "./components/Faq";
import Footer from "./components/Footer";

const Website = () => {
  return (
    <div className="mx-auto sm:mx-0 sm:min-w-[100vw] min-w-[850px] max-w-[1500px] h-full relative">
      <Header />
      <div className="flex flex-col gap-2">
        <Hero />
        <Features />
        <MidHero />
        <Testimonials />
        <Faq />
      </div>
      <Footer />
    </div>
  );
};

export default Website;
