import { useEffect } from "react";
import { useChangePasswordMutation } from "Network/Auth/password";
import { toast } from "react-toastify";

const useChangePassword = (setOpenChangepassword) => {
  const [handleChangePassword, changePasswordState] =
    useChangePasswordMutation();

  useEffect(() => {
    if (changePasswordState.isSuccess) {
      toast.success("Password Changed");
      setOpenChangepassword(false);
    }
    if (changePasswordState.error) {
      toast.error(changePasswordState.error?.data?.message);
    }
  }, [changePasswordState.isLoading]);

  return [handleChangePassword, changePasswordState];
};

export default useChangePassword;
