import { Formik } from "formik";
import * as Yup from "yup";

import Input from "Component/Forms/Input";
import Button from "Component/Forms/Button";
import useAuthStore, {
  AuthModalPages,
} from "Screens/SchoolWebpages/hooks/useAuthStore";
import { useResetPasswordMutation } from "Network/Auth/password";

const ResetPassword = () => {
  const [resetPassword, resetPasswordState] = useResetPasswordMutation();
  const { setPage } = useAuthStore();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = (values) => {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="min-h-[30vh] w-full bg-white mt-3">
          {resetPasswordState.isUninitialized ||
          !resetPasswordState.isSuccess ? (
            <div className="flex flex-col gap-4 w-full">
              <p className="text-center text-[14px] text-primary font-semibold cursor-pointer">
                Enter New Password
              </p>

              <Input
                withTitle
                title="Password"
                placeholder="Enter password"
                name="password"
                type="password"
              />

              <Input
                withTitle
                title="Confirm Password"
                placeholder="Enter confirm password"
                name="confirmPassword"
                type="password"
              />

              <Button
                isLoading={resetPasswordState.isLoading}
                handleSubmit={props.handleSubmit}
                title="Set"
              />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-6">
              <p className="text-center text-[14px]">
                Your new password has been set, Click on the Log in below
              </p>
              <p
                className="cursor-pointer text-center text-[14px] text-primary font-semibold"
                onClick={() => setPage(AuthModalPages.LOGIN)}
              >
                Log in
              </p>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
};

export default ResetPassword;
