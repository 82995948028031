import cn from "Utils/cn";

const ColumnItem = ({ icon, text, link }) => {
  return (
    <a
      href={link}
      className={cn(
        "flex items-center gap-1",
        link && "cursor-pointer hover:text-[#5e91c1]",
      )}
    >
      {icon} <p className="text-[16px] sm:text-[12px]">{text}</p>
    </a>
  );
};

export default ColumnItem;
