/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const AppSlice = createSlice({
  name: "appslice",
  initialState: {
    toggleMobileNav: false,
    toggleNav: true,
    registrationSuccess: false,
    shortName: null,
  },
  reducers: {
    setToggleMobileNav: (state, action) => ({
      ...state,
      toggleMobileNav: action.payload,
    }),
    setToggleNav: (state, action) => ({
      ...state,
      toggleNav: action.payload,
    }),

    setRegistration: (state, action) => {
      state.shortName = action.payload.data;
      state.registrationSuccess = action.payload.success;
    },
  },
});

export const { setToggleMobileNav, setToggleNav, setRegistration } =
  AppSlice.actions;
export default AppSlice.reducer;
