/* eslint-disable no-unneeded-ternary */
import cn from "../Utils/cn";

const Logo = ({ className = "", image = null, variant = "primary" }) => {
  const logoPath =
    variant === "primary" ? "/images/logo.png" : "/images/logo-white.png";

  // TODO: find a way to resize the image without distorting it quality
  return (
    <img
      className={cn(
        "h-[60px] sm:h-[40px] w-[150px] sm:w-[110px]",
        `${image && "min-h-[60px]  w-[70px] sm:w-[80px] inset-0 object-cover"}`,
        className,
      )}
      src={image ? image : logoPath}
      alt=""
      srcSet=""
    />
  );
};

export default Logo;
